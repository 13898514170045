import React from "react";
import { useLanguage } from "../hooks/ContextLanguage";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import "../support_folder/360View.css";
import Loading from "./Loading";

function View360() {
  const { isGreek } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [visibilityCSS, setVisibilityCSS] = useState("hidden");

 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.body.style.overflow = !isLoading ? "hidden" : "unset";
  }, [isLoading]);


  const handleLoad = () => {

    setIsLoading(true);
    setVisibilityCSS("visible");
  };


  return (
    <>
      {!isLoading && <Loading />}
      <div style={{ visibility: visibilityCSS }}>
        <Grid container>
          <Grid
            item
            className="col1"
            xs="12"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 style={{ marginTop: "30px", textAlign: "center" }}>
              {isGreek
                ? `Καλώς ήρθατε στο 360 View μας`
                : <div>Welcome to our 360 View of the<br/>Engleistra of Saint Neophytos</div>}
            </h1>
          </Grid>

          <Grid
            item
            className="col1"
            xs="12"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom:"20px"
            }}
          >
           
            <div>
              <iframe
                src="https://my.matterport.com/show/?m=xU2LV8tRohu"
                id="sizeOfVirtualTour"
                frameborder="0"
                allowfullscreen
                webkitallowfullscreen
                mozallowfullscreen
                allow="xr-spatial-tracking"
                onLoad={handleLoad}
              ></iframe>

              
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default View360;
