import React from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLanguage } from "../../hooks/ContextLanguage";
import { useState,useEffect } from "react";
import Loading from "../Loading";
const MainGamePage = () => {

  const { isGreek } = useLanguage();



  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const randomTime = Math.floor(Math.random() * (500 - 100 + 1)) + 500; 
    const timer = setTimeout(() => {
      setLoading(false);
    }, randomTime);

    return () => clearTimeout(timer); 
  }, []);

  if (loading) {
    return <Loading />;
  }



  return (
    <Grid
      container
      className="col1"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Grid
        item
        style={{ height: "190px", textAlign: "center" }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        {" "}
        <h1 style={{ marginTop: "30px" }}>{isGreek ? "Καλώς ήρθατε στη σελίδα παιχνιδιών" : "Welcome to games page"}</h1>
      </Grid>
      <Grid
        item
        style={{
          marginTop: "50px",
          marginBottom:"30px",
          textAlign: "center",
          padding:"10px",
          boxShadow:
            "0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.3), 0px -2px 6px 0px rgba(10, 37, 64, 0.35) inset",
        }}
        xs={8}
        sm={6}
        md={4}
        lg={2}
        xl={2}
      >
        <span style={{ fontSize: "30px" }}>{isGreek ? "Επιλέξτε δυσκολία" : "Choose difficulty"}</span>
        <br />
        <Button
          variant="contained"
          component={Link}
          to="/games/lvl1"
          sx={{
            marginTop: "30px",
            width: "110px",
            color: "white", 
            textDecoration: "none",
            textTransform: 'none',
            
            "&:hover": {
              color: "white",
              textDecoration: "none",
            },
          }}
        >
          {isGreek ? "Επίπεδο 1" : "Level 1"}
        </Button>
        <br />
        <Button
          variant="contained"
          component={Link}
          to="/games/lvl2"
          sx={{
            marginTop: "30px",
            width: "110px",
            color: "white", 
            textDecoration: "none",
            textTransform: 'none',
            
            "&:hover": {
              color: "white", 
              textDecoration: "none",
            },
          }}
        >
          {isGreek ? "Επίπεδο 2" : "Level 2"}
        </Button>
        <br />
        <Button
          variant="contained"
          component={Link}
          to="/games/lvl3"
          sx={{
            marginTop: "30px",
            marginBottom: "30px",
            width: "110px",
            color: "white", 
            textDecoration: "none",
            textTransform: 'none',
           
            "&:hover": {
              color: "white", 
              textDecoration: "none",
            },
          }}
        >
          {isGreek ? "Επίπεδο 3" : "Level 3"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default MainGamePage;
