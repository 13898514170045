import React from 'react';
import { CircularProgress, Box, Hidden } from '@mui/material';



const Loading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="80vh" >
     <CircularProgress color="primary" size={80} thickness={4} />
  </Box>
);

export default Loading;
