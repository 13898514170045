import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useLanguage } from "../hooks/ContextLanguage";
import pdf_icon from "../assets/img/Saint_Neophytos/pdf_icon.jpg";
import { Button } from "@mui/material";
import "../support_folder/bibliography.css"

import Loading from "./Loading";
import { useEffect } from "react";

const Bibliography = () => {
  const { isGreek } = useLanguage();

  const [visibilityCSS,setVisibilityCSS] = useState("hidden")

  const [buttonNameGreek, setButtonNameGreek] = useState("Αναφερω");

  const [buttonNameEnglish, setButtonNameEnglish] = useState("Cite");

  const [buttonNameGreek1, setButtonNameGreek1] = useState("Αναφερω");

  const [buttonNameEnglish1, setButtonNameEnglish1] = useState("Cite");


  const [buttonNameGreek2, setButtonNameGreek2] = useState("Αναφερω");

  const [buttonNameEnglish2, setButtonNameEnglish2] = useState("Cite");

  const [buttonNameGreek3, setButtonNameGreek3] = useState("Αναφερω");

  const [buttonNameEnglish3, setButtonNameEnglish3] = useState("Cite");

  const [buttonNameGreek4, setButtonNameGreek4] = useState("Αναφερω");

  const [buttonNameEnglish4, setButtonNameEnglish4] = useState("Cite");

  const [buttonNameGreek5, setButtonNameGreek5] = useState("Αναφερω");

  const [buttonNameEnglish5, setButtonNameEnglish5] = useState("Cite");

  const [buttonNameGreek6, setButtonNameGreek6] = useState("Αναφερω");

  const [buttonNameEnglish6, setButtonNameEnglish6] = useState("Cite");

  const [buttonNameGreek7, setButtonNameGreek7] = useState("Αναφερω");

  const [buttonNameEnglish7, setButtonNameEnglish7] = useState("Cite");

  const [buttonNameGreek8, setButtonNameGreek8] = useState("Αναφερω");

  const [buttonNameEnglish8, setButtonNameEnglish8] = useState("Cite");

  const [buttonNameGreek9, setButtonNameGreek9] = useState("Αναφερω");

  const [buttonNameEnglish9, setButtonNameEnglish9] = useState("Cite");

  const [buttonNameGreek10, setButtonNameGreek10] = useState("Αναφερω");

  const [buttonNameEnglish10, setButtonNameEnglish10] = useState("Cite");

  const [buttonNameGreek11, setButtonNameGreek11] = useState("Αναφερω");

  const [buttonNameEnglish11, setButtonNameEnglish11] = useState("Cite");

  const [buttonNameGreek12, setButtonNameGreek12] = useState("Αναφερω");

  const [buttonNameEnglish12, setButtonNameEnglish12] = useState("Cite");

  const handlePdfClick = (e) => {
    e.preventDefault(); 
    const pdfLink = e.currentTarget.getAttribute('pdf');
 
  const width = 1100;
  const height = 1000;

  
  const left = (window.screen.width / 2) - (width / 2);
  const top = (window.screen.height / 2) - (height / 2);

  const popup = window.open(pdfLink, '_blank', `width=${width},height=${height},left=${left},top=${top}`);

 
  if (popup) popup.focus();
  };

  const handleClick = (e,id) => {
  
    const dataToCopy = e.currentTarget.getAttribute('data');

    
    navigator.clipboard.writeText(dataToCopy)

    if(id==0)
    {
      if(isGreek)
      {
  
        
        setButtonNameGreek("Αντιγραφηκε");
      
      setTimeout(() => {
        setButtonNameGreek("Αναφερω");
      }, 1500); 
      
  
      }
      else
      {
  
        setButtonNameEnglish("Copied");
  
        setTimeout(() => {
          setButtonNameEnglish("Cite");
        }, 1500); 
       
    }
    }
    else if(id==1)
    {
      if(isGreek)
      {
  
        
        setButtonNameGreek1("Αντιγραφηκε");
      
      setTimeout(() => {
        setButtonNameGreek1("Αναφερω");
      }, 1500); 
      
  
      }
      else
      {
  
        setButtonNameEnglish1("Copied");
  
        setTimeout(() => {
          setButtonNameEnglish1("Cite");
        }, 1500); 
       
    }
    }
    else if(id==2)
    {
      if(isGreek)
      {
  
        
        setButtonNameGreek2("Αντιγραφηκε");
      
      setTimeout(() => {
        setButtonNameGreek2("Αναφερω");
      }, 1500); 
      
  
      }
      else
      {
  
        setButtonNameEnglish2("Copied");
  
        setTimeout(() => {
          setButtonNameEnglish2("Cite");
        }, 1500); 
       
    }
    }
    else if(id==3)
    {
      if(isGreek)
      {
  
        
        setButtonNameGreek3("Αντιγραφηκε");
      
      setTimeout(() => {
        setButtonNameGreek3("Αναφερω");
      }, 1500); 
      
  
      }
      else
      {
  
        setButtonNameEnglish3("Copied");
  
        setTimeout(() => {
          setButtonNameEnglish3("Cite");
        }, 1500); 
       
    }
    }
    else if(id==4)
      {
        if(isGreek)
          {
      
            
          setButtonNameGreek4("Αντιγραφηκε");
          setTimeout(() => {
            setButtonNameGreek4("Αναφερω");
          }, 1500); 
          
      
          }
          else
          {
      
            setButtonNameEnglish4("Copied");
            setTimeout(() => {
              setButtonNameEnglish4("Cite");
            }, 1500); 
        }
      }
      else if(id==5)
        {
          if(isGreek)
            {
        
              
            setButtonNameGreek5("Αντιγραφηκε");
            setTimeout(() => {
              setButtonNameGreek5("Αναφερω");
            }, 1500); 
            
        
            }
            else
            {
        
              setButtonNameEnglish5("Copied");
              setTimeout(() => {
                setButtonNameEnglish5("Cite");
              }, 1500); 
          }
        }
        else if(id==6)
          {
            if(isGreek)
              {
          
                
              setButtonNameGreek6("Αντιγραφηκε");
              setTimeout(() => {
                setButtonNameGreek6("Αναφερω");
              }, 1500); 
              
          
              }
              else
              {
          
                setButtonNameEnglish6("Copied");
                setTimeout(() => {
                  setButtonNameEnglish6("Cite");
                }, 1500); 
            }
          }
          else if(id==7)
            {
              if(isGreek)
                {
            
                  
                setButtonNameGreek7("Αντιγραφηκε");
                setTimeout(() => {
                  setButtonNameGreek7("Αναφερω");
                }, 1500); 
                
            
                }
                else
                {
            
                  setButtonNameEnglish7("Copied");
                  setTimeout(() => {
                    setButtonNameEnglish7("Cite");
                  }, 1500); 
              }
            }
            else if(id==8)
              {
                if(isGreek)
                  {
              
                    
                  setButtonNameGreek8("Αντιγραφηκε");
                  setTimeout(() => {
                    setButtonNameGreek8("Αναφερω");
                  }, 1500); 
                  
              
                  }
                  else
                  {
              
                    setButtonNameEnglish8("Copied");
                    setTimeout(() => {
                      setButtonNameEnglish8("Cite");
                    }, 1500); 
                }
              }
              else if(id==9)
                {
                  if(isGreek)
                    {
                
                      
                    setButtonNameGreek9("Αντιγραφηκε");
                    setTimeout(() => {
                      setButtonNameGreek9("Αναφερω");
                    }, 1500); 
                    
                
                    }
                    else
                    {
                
                      setButtonNameEnglish9("Copied");
                      setTimeout(() => {
                        setButtonNameEnglish9("Cite");
                      }, 1500); 
                  }
                }
                else if(id==10)
                  {
                    if(isGreek)
                      {
                  
                        
                      setButtonNameGreek10("Αντιγραφηκε");
                      setTimeout(() => {
                        setButtonNameGreek10("Αναφερω");
                      }, 1500); 
                      
                  
                      }
                      else
                      {
                  
                        setButtonNameEnglish10("Copied");
                        setTimeout(() => {
                          setButtonNameEnglish10("Cite");
                        }, 1500); 
                    }
                  }
                  else if(id==11)
                    {
                      if(isGreek)
                        {
                    
                          
                        setButtonNameGreek11("Αντιγραφηκε");
                        setTimeout(() => {
                          setButtonNameGreek11("Αναφερω");
                        }, 1500); 
                        
                    
                        }
                        else
                        {
                    
                          setButtonNameEnglish11("Copied");
                          setTimeout(() => {
                            setButtonNameEnglish11("Cite");
                          }, 1500); 
                      }
                    }


  };
  
 


  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    window.scrollTo(0, 0);
    const randomTime = Math.floor(Math.random() * (500 - 100 + 1)) + 500; 
    const timer = setTimeout(() => {
      setLoading(false);
      setVisibilityCSS("visible")
    }, randomTime);

    return () => clearTimeout(timer); 
  }, []);

  useEffect(() => {
  
    document.body.style.overflow = loading ? 'hidden' : 'unset';
  }, [loading]);



  return (
    <>
    {loading && ( 
       <Loading/>
      )}
 <div style={{ visibility: visibilityCSS }}>
      <Grid container>
        <Grid
          item
          className="col1"
          xs="12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 style={{ marginTop: "30px", textAlign: "center",padding:"15px" }}>
            {isGreek
              ? `Καλώς ήρθατε στην βιβλιογραφια μας`
              : `Welcome to our bibliography`}
          </h1>
        </Grid>

        <Grid
          item
          className="col1"
          xs="12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{ width: "50%", marginTop: "20px" }}
            className="center-div-small-screen"
          ></div>
        </Grid>

        <Grid
          item
          className="col1"
          xs="12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div  className= "smaller-screen-size-of-div"
            style={{
             
              
              marginTop: "20px",
              backgroundColor: "white",
              padding: "20px",
              marginBottom:"40px"
            }}
            
          >
            <h4 className="textAlign-small-screen">
              {isGreek
                ? `Εδω μπορειτε να βρειτε την βιβλιογραφια μας`
                : `Here you can find our bibliography`}
            </h4>

            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto"}}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>Cyric Mango and Ernest J. W. Hawkins</span> </p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}> THE HERMITAGE OF ST. NEOPHYTOS AND ITS WALL PAINTINGS</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>155</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>PDF</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>1966</span></p>
                </p>
              </div>

              <div style={{ flex: "0 0 40px" }}>
                <a
                 
                  class="pdf-link"
                  pdf="/PDFS/The_Hermitage_of_St_Neophytos_and_its_wall_paintings_compressed.pdf"
                  onClick={handlePdfClick}
                >
                  <img
                    src={pdf_icon}
                    style={{ width: "30px", height: "30px" }}
                    alt="pdf_icon"
                  />
                </a>
              </div>

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,0)}
                  data="Mango, C., & Ernest J. W. Hawkins. (1966). The Hermitage of St. Neophytos and Its Wall Paintings. Dumbarton Oaks Papers, 20, 119–206. https://doi.org/10.2307/1291245"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek : buttonNameEnglish}
                  
                </Button>
              </div>
            </div>



            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>Ioanna Kakoulli and Christian Fischer</span> </p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>The Techniques and Materials of the Wall Paintings at the Engleistra of St. Neophytos (Phase II)</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>5</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>PDF</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>2011-2012</span></p>
                </p>
              </div>

              <div style={{ flex: "0 0 40px" }}>
                <a
                 
                  class="pdf-link"
                  pdf="/PDFS/The_techniques_and_materials_of_the_wall_paintings_at_the_Enkleistra_of_St_Neophytos_Phase_II_compressed.pdf"
                  onClick={handlePdfClick}
                >
                  <img
                    src={pdf_icon}
                    style={{ width: "30px", height: "30px" }}
                    alt="pdf_icon"
                  />
                </a>
              </div>

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,1)}
                  data="Ioanna Kakoulli, Christian Fischer (2011-2012). The Techniques and Materials of the Wall Paintings at the Engleistra of St. Neophytos (Phase II)"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek1 : buttonNameEnglish1}
                  
                </Button>
              </div>
            </div>


            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>unknown</span> </p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>The Monastery of St. Neophytos</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>5</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>Book/PDF</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>unknown</span></p>
                </p>
              </div>

              <div style={{ flex: "0 0 40px" }}>
                <a
                 
                  class="pdf-link"
                  pdf="/PDFS/Cyprus___Byzantine_churches_and_Monasteries___Saint_Neophytos_compressed.pdf"
                  onClick={handlePdfClick}
                >
                  <img
                    src={pdf_icon}
                    style={{ width: "30px", height: "30px" }}
                    alt="pdf_icon"
                  />
                </a>
              </div>

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,2)}
                  data="data not found fo this citation"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek2 : buttonNameEnglish2}
                </Button>
              </div>
            </div>
                  


            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>Ioanna Kakoulli and Christian Fischer</span> </p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>An Innovative Noninvasive and Nondestructive Multidisciplinary Approach 
                  for the Technical Study of the Byzantine Wall Paintings in the Engleistra of St. Neophytos in Paphos Cyprus</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>7</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>PDF</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>2008-2009</span></p>
                </p>
              </div>

              <div style={{ flex: "0 0 40px" }}>
                <a
                 
                  class="pdf-link"
                  pdf="/PDFS/Technical_Study_for_the_wall_paintings_of_the_Enkleistra_of_St._Neophytos_compressed.pdf"
                  onClick={handlePdfClick}
                >
                  <img
                    src={pdf_icon}
                    style={{ width: "30px", height: "30px" }}
                    alt="pdf_icon"
                  />
                </a>
              </div>

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,3)}
                  data="Ioanna Kakoulli, Christian Fischer (2008-2009). An Innovative Noninvasive and Nondestructive Multidisciplinary Approach for the Technical Study of the Byzantine Wall Paintings in the Engleistra of St. Neophytos in Paphos Cyprus"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek3 : buttonNameEnglish3}
                  
                </Button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ</span> </p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>ΕΓΚΛΕΙΣΤΡΙΩΤΙΚΑ ΑΝΑΛΕΚΤΑ 1. ΠΡΑΚΤΙΚΑ Α΄ ΔΙΕΘΝΟΥΣ ΣΥΝΕΔΡΙΟΥ ΑΓΙΟΣ ΝΕΟΦΥΤΟΣ
                  Ο ΕΓΚΛΕΙΣΤΟΣ ΙΣΤΟΡΙΑ - ΘΕΟΛΟΓΙΑ - ΠΟΛΙΤΙΣΜΟΣ</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>1008</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>ΒΟΟΚ</span></p>

                    <p style={{ margin: "0px", fontWeight: "bold" }}> ISBN: <span style={{fontWeight:"normal"}}>978-9963-613-13-7</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>2009</span></p>
                </p>
              </div>

              <div style={{ flex: "0 0 40px" }}>
                <a
                 
                  class="pdf-link"
                  pdf="/PDFS/Neophytos_the_Recluse_and_his_Typikon_Te_Compressed.pdf"
                  onClick={handlePdfClick}
                >
                  <img
                    src={pdf_icon}
                    style={{ width: "30px", height: "30px" }}
                    alt="pdf_icon"
                  />
                </a>
              </div>

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,4)}
                  data="ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ (2009). ΕΓΚΛΕΙΣΤΡΙΩΤΙΚΑ ΑΝΑΛΕΚΤΑ 1. ΠΡΑΚΤΙΚΑ Α΄ ΔΙΕΘΝΟΥΣ ΣΥΝΕΔΡΙΟΥ ΑΓΙΟΣ ΝΕΟΦΥΤΟΣ
                  Ο ΕΓΚΛΕΙΣΤΟΣ ΙΣΤΟΡΙΑ - ΘΕΟΛΟΓΙΑ - ΠΟΛΙΤΙΣΜΟΣ"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek4 : buttonNameEnglish4}
                  
                </Button>
              </div>
            </div>


            





            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>A. PAPAGEORGIOU</span> </p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>The Monastery Of Agios Neophytos HISTORY AND ART A SHORT GUIDE</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>63</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>BOOK</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> ISBN: <span style={{fontWeight:"normal"}}>9963-614-03-5</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>2005</span></p>
                </p>
              </div>

          

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,5)}
                  data="A. PAPAGEORGIOU (2005). The Monastery Of Agios Neophytos HISTORY AND ART A SHORT GUIDE"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek5 : buttonNameEnglish5}
                  
                </Button>
              </div>
            </div>




            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ</span> </p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ Α'</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>429</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>BOOK</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> ISBN: <span style={{fontWeight:"normal"}}>9963-614-00-0</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>1996</span></p>
                </p>
              </div>

          

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,6)}
                  data="ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ (1996). ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ Α'"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek6 : buttonNameEnglish6}
                  
                </Button>
              </div>
            </div>



            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ</span> </p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ B'</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>499</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>BOOK</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> ISBN: <span style={{fontWeight:"normal"}}>9963-614-00-1</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>1998</span></p>
                </p>
              </div>

          

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,7)}
                  data="ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ (1998). ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ B'"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek7 : buttonNameEnglish7}
                  
                </Button>
              </div>
            </div>




            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ</span> </p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ Γ'</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>619</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>BOOK</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> ISBN: <span style={{fontWeight:"normal"}}>9963-614-00-2</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>1999</span></p>
                </p>
              </div>

          

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,8)}
                  data="ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ (1999). ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ Γ'"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek8 : buttonNameEnglish8}
                  
                </Button>
              </div>
            </div>



            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ</span></p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ Δ'</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>743</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>BOOK</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> ISBN: <span style={{fontWeight:"normal"}}>9963-614-08-6</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>2001</span></p>
                </p>
              </div>

          

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,9)}
                  data="ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ (2001). ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ Δ'"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek9 : buttonNameEnglish9}
                  
                </Button>
              </div>
            </div>



            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ</span></p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ Ε'</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>503</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>BOOK</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> ISBN: <span style={{fontWeight:"normal"}}>9963-614-10-8</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>2005</span></p>
                </p>
              </div>

          

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,10)}
                  data="ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ (2005). ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ E'"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek10 : buttonNameEnglish10}
                  
                </Button>
              </div>
            </div>


            <div
              style={{
                display: "flex",
                maxWidth: "auto",
                maxHeight: "auto",
                border: "solid 1px black",
                marginTop:"10px",
                padding:"7px"
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <p style={{ margin: "0px", padding: "4px" }}>
                  <p style={{ margin: "0px", fontWeight: "bold" }}> Author(s): <span style={{fontWeight:"normal"}}>ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ</span></p> 

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Title: <span style={{fontWeight:"normal"}}>ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ ΣΤ'</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Pages: <span style={{fontWeight:"normal"}}>272</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Type: <span style={{fontWeight:"normal"}}>BOOK</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> ISBN: <span style={{fontWeight:"normal"}}>978-9963-614-11-0</span></p>

                  <p style={{ margin: "0px", fontWeight: "bold" }}> Year: <span style={{fontWeight:"normal"}}>2008</span></p>
                </p>
              </div>

          

              <div style={{ flex: "0 0 80px" }}>
                <Button
                  variant="contained"
                  onClick={(e)=> handleClick(e,11)}
                  data="ΙΕΡΑΣ ΒΑΣΙΛΙΚΗΣ ΚΑΙ ΣΤΑΥΡΟΠΗΓΙΑΚΗΣ ΜΟΝΗΣ ΑΓΙΟΥ ΝΕΦΥΤΟΥ (2008). ΑΓΙΟΥ ΝΕΟΦΥΤΟΥ ΤΟΥ ΕΓΚΛΕΙΣΤΟΥ. ΣΥΓΓΡΑΜΜΑΤΑ ΤΟΜΟΣ ΣΤ'"
                  sx={{
                    height: "35px",
                    width: "auto",
                    color: "white",
                    padding:"10px",
                    textDecoration: "none",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                 {isGreek ?  buttonNameGreek11 : buttonNameEnglish11}
                  
                </Button>
              </div>
            </div>



            

          </div>
        </Grid>
      </Grid>
      </div>
    </>
  );
};

export default Bibliography;
