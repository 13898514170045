import React from "react";
import { Grid } from "@mui/material";
import Saint_Neofytos from "../assets/img/Saint_Neophytos/pic93.jpg";
import { useLanguage } from "../hooks/ContextLanguage";
import {Button} from "@mui/material";
import { Link } from "react-router-dom";
import "../support_folder/Saint_Neophytos_Engleitra_And_Monastery.css"
import { useEffect } from "react";
const SaintNeofytos = () => {
  const { isGreek } = useLanguage();

  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      container
      className="col1"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Grid item style={{display: "flex",
 justifyContent: "center",
 marginTop: "50px",
 boxShadow: "rgba(0, 0, 0, 0.35) 0px -50px 180px -28px inset",
 borderRadius:"50px" }} sm={7} md={7} lg={7}>
           <img
           className="change_photo_size"
          src={Saint_Neofytos}
          alt="Saint Neophytos"
          style={{
            maxHeight: "750px",
            borderRadius: "20px",marginTop:"25px"
            ,marginBottom:"25px",
          }}
        />
      </Grid>
      <Grid
        className="col2"
        style={{ marginTop: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <h3 className="textInCenter">
          {isGreek ? "Σχετικά με τον Άγιο Νεόφυτο" : "About Saint Neophytos"}
        </h3>
      </Grid>
      <Grid
        className="col2"
        style={{
          marginTop: "15px",
          textAlign: "justify",
          marginBottom: "70px",
        }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <p style={{ margin: "0px" }} className="padding-for-smaller-screens">
          {isGreek
            ? `Ο Άγιος Νεόφυτος γεννήθηκε το 1134 μ.Χ. στο χωριό Λεύκαρα της Κύπρου. Επίσης Συνδέεται με τη Μονή του Αγίου Νεοφύτου στην Πάφο. Ο Άγιος Νεόφυτος ενδιαφερόταν
             να ζήσει μια ενάρετη μοναστική ζωή. Το πραγματικό του όνομα ήταν Νικόλαος, αλλά υιοθέτησε το όνομα Νεόφυτος για τη μοναστική του ζωή.
              Όταν ο Νεόφυτος ήταν νέος, αποφάσισε να ζήσει μια πνευματική και ενάρετη ζωή. Άρχισε να ζει σε μια σπηλιά στην Πάφο, συγκεκριμένα στο χωριό Τάλα,
               σε μια σπηλιά που ονομαζόταν Εγκλείστρα.`

            : `Saint Neophytos was born in 1134 AD in the village of Lefkara in Cyprus. He is associated with the Monastery of Saint Neophytos in Paphos.
             Saint Neophytos was interested in living a virtuous monastic life. His real name was Nikolaos, but he adopted the name Neophytos for his monastic life.
              When Neophytos was young, he decided to live a spiritual and virtuous life. He started to live in a cave in Paphos, specifically in the village of Tala,
               in a cave named Engleistra.`}
        </p>
      </Grid>
      <Grid
        className="col2"
        style={{ textAlign: "justify", marginBottom: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <p style={{ margin: "0px" }} className="padding-for-smaller-screens">
          {isGreek
            ? `Η ασκητική ζωή του Αγίου Νεοφύτου άρχισε να κερδίζει αναγνώριση, προσελκύοντας μαθητές που ενδιαφέρονταν για τη μοναστική ζωή.
             Αυτό οδήγησε στη δημιουργία μιας μοναστικής κοινότητας. Λόγω του διευρυνόμενου αριθμού των οπαδών, ο Άγιος Νεόφυτος ίδρυσε τη Μονή του Αγίου Νεοφύτου,
              γνωστή και ως Εγκλείστρα του Αγίου Νεοφύτου, το 1170 μ.Χ.
             Η Εγκλείστρα αναφέρεται στο κελί που δημιούργησε ο Νεόφυτος στο σπήλαιο της Τάλας και είναι επίσης
               ο τόπος όπου ετάφη ο Νεόφυτος.`

            : `Saint Neophytos ascetic life began to gain recognition, attracting disciples interested in monastic life. This led to the formation of a monastic community.
             Due to the expanding number of followers, Saint Neophytos founded the Saint Neophytos Monastery, also known as the Engleistra of Saint Neophytos,
             in 1170 AD. The Engleistra refers to the cell that Neophytos created in the cave of Tala, which is also where he was buried.`}
        </p>
      </Grid>

      <Grid
        className="col2"
        style={{ textAlign: "justify", marginBottom: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <p style={{ margin: "0px" }} className="padding-for-smaller-screens">
          {isGreek
            ? `Ο Άγιος Νεόφυτος έγραψε για τον εαυτό του μεταξύ 1134 μ.Χ και 1219 μ.Χ. Σύμφωνα με τα γραπτά του, γεννήθηκε σε μια φτωχή οικογένεια στο Κάτω Δρυ,
             κοντά στα Λεύκαρα της επαρχίας Λάρνακας, και μεγάλωσε χωρίς επίσημη εκπαίδευση. Έγινε δόκιμος στη Μονή του Αγίου Χρυσοστόμου,
              όπου έμαθε να διαβάζει και να γράφει. Ο Νεόφυτος συνέγραψε έργα για την Κατοχή της Κύπρου από τον Ριχάρδο τον Πρώτο το 1191 μ.Χ
               και έγραψε αρκετές θεολογικές πραγματείες. Λόγω του αυξανόμενου αριθμού των επισκεπτών, παρακινήθηκε να σκάψει ένα επιπλέον σπήλαιο,
                που ονομάστηκε Νέα Σιών, πάνω από την Εγκλείστρα, για να βρει ένα ήσυχο μέρος και εσωτερική γαλήνη.`
            : `Saint Neophytos wrote about himself between 1134 AD and 1219 AD. According to his writings,
             he was born into a poor family in Kato Dry, near Lefkara in the province of Larnaca, and grew up without a formal education. He became
              a novice at the Monastery of Agios Chrysostom, where he learned to read and write. Neophytos composed works on the subjugation of Cyprus
               by Richard the First in 1191 AD and wrote several theological treatises. Due to the increasing number of visitors, he was caused to dig an 
               additional cave, named New Zion, above the Engleistra, to find a quiet place and inner peace.

            `}
        </p>
      </Grid>


      <Grid
        className="col2"
        style={{ display:"flex",justifyContent:"center", marginBottom: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
       <Button
          variant="contained"
          component={Link}
          to="/"
          sx={{
            marginTop: "30px",
            width: "250px",
            color: "white", 
            textDecoration: "none",
            textTransform: 'none',
          
            "&:hover": {
              color: "white",
              textDecoration: "none",
            },
          }}
        >
          {isGreek ? "ΠΙΣΩ ΣΤΗΝ ΑΡΧΙΚΗ ΣΕΛΙΔΑ" : "BACK TO HOME PAGE"}
        </Button>

      </Grid>


    </Grid>
  );
};

export default SaintNeofytos;
