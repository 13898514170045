import React from "react";
import Loading from "./Loading";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "../support_folder/youtubeVideos.css";
import { useLanguage } from "../hooks/ContextLanguage";
const VideosYoutube = () => {
  const [loading, setLoading] = useState(true);
  const [visibilityCSS, setVisibilityCSS] = useState("hidden");

  const { isGreek } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
    const randomTime = Math.floor(Math.random() * (1000 - 500 + 1)) + 1000;
    const timer = setTimeout(() => {
      setLoading(false);
      setVisibilityCSS("visible");
    }, randomTime);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  return (
    <>
      {loading && <Loading />}
      <div style={{ visibility: visibilityCSS }}>
        <Grid container className="center-right-logos" spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                textAlign: "center",
                marginTop: "30px",
                padding: "15px",
              }}
            >
              <h2>{!isGreek ? `Video Gallery` : `Βίντεο Γκαλερί`}</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>

          <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "center",
               textAlign:"center"
              }}
            >
              <div style={{ width: "70%",marginTop:"30px"}}>
                <h3 style={{marginBottom:"40px"}}>
                    {!isGreek ? `Capture details of Agios Neophytos Monastery using drones` : `Αποτύπωση λεπτομερειών της Μονής Αγίου Νεοφύτου με τη χρήση drones`}
                </h3>
                 
                
              </div>
            </Grid>



            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <iframe
                width="70%"
                height="370px"
                src="https://www.youtube.com/embed/ikDS6dfxlEk?si=AdzsAUNOnAge6Zcs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </Grid>

     
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>


          <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div style={{ width: "70%",marginTop:"30px" }}>
                <h3 style={{marginBottom:"40px"}}>
                    {!isGreek ? `The final 3D model of the Engleistra of Saint Neophytos` : `Το τελικό τρισδιάστατο μοντέλο της Εγκλείστρας του Αγίου Νεοφύτου`}
                </h3>
                 
                
              </div>
            </Grid>


            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <iframe
                style={{marginBottom:"20px"}}
                width="70%"
                height="370px"
                src="https://www.youtube.com/embed/86mvuWLWCfI?si=Wiz2KmAnera8aUDY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default VideosYoutube;
