import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Nav, NavDropdown } from "react-bootstrap";
import { Height, Translate } from "@mui/icons-material";
import { useLanguage } from "../hooks/ContextLanguage";
import flagGreece from "../assets/img/flagGreece.png";
import flagEngland from "../assets/img/flagUnitedKingdom.png";
import "../support_folder/fontSizeNav.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/Logo_Saint_Neophytos.jpg";
// import logo from "../assets/img/Logo.jpg";
import useWindowWidth from "../hooks/useWindowWidth";

const NavigationBar = () => {
  const windowWidth = useWindowWidth();

  const { isGreek, greekLanguage, englishLanguage } = useLanguage();

  const [activeLink, setActiveLink] = useState("homePage");

  const [activeLink2, setActiveLink2] = useState("English");

  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = (page) => {
    console.log("ff");
    console.log(expanded);
    setActiveLink(page);
    setExpanded(false);
  };

  const handleLinkClick2 = (page) => {
    setActiveLink2(page);
    setExpanded(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      style={{ minHeight: 80, position: "sticky", top: 0, zIndex: 1000 }}
      onToggle={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      <Link
        to="/"
        className={`navbar-custom`}
        onClick={() => handleLinkClick("homePage")}
        style={{ marginTop:"3px",color: "white" }}
      >
        <img className="size-of-logo-img"  src={logo} alt="" />
      </Link>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Link
            to="/"
            className={`navbar-custom ${
              activeLink === "homePage"
                ? 'active-link navbar-custom"'
                : 'navbar-custom"'
            }`}
            onClick={() => handleLinkClick("homePage")}
            style={{ padding: "8px" }}
          >
            {isGreek ? "Αρχική" : "Homepage"}
          </Link>

          <Link
            to="/360View"
            className={`navbar-custom ${
              activeLink === "360 View"
                ? 'active-link navbar-custom"'
                : 'navbar-custom"'
            }`}
            onClick={() => handleLinkClick("360 View")}
            style={{ padding: "8px" }}
          >
            {isGreek ? "360 View" : "360 View"}
          </Link>

          <Link
            to="/e-book"
            className={`navbar-custom ${
              activeLink === "eBook"
                ? 'active-link navbar-custom"'
                : 'navbar-custom"'
            }`}
            onClick={() => handleLinkClick("eBook")}
            style={{ padding: "8px" }}
          >
            {isGreek ? "Ebooks" : "Ebooks"}
          </Link>

          <NavDropdown
            title={isGreek ? "Καταλογος" : "Repository"}
            id="collasible-nav-dropdown"
            className="navbar-custom"
            style={{ paddingLeft: "8px", paddingRight: "0px" }}
          >
            <NavDropdown.Item style={{ padding: "0", paddingLeft: "5px" }}>
              <Link
                to="/photos"
                className={`navbar-custom navbar-dropdown ${
                  activeLink === "photos"
                    ? 'active-link2 navbar-custom"'
                    : 'navbar-custom"'
                }`}
                onClick={() => handleLinkClick("photos")}
                style={{
                  display: "block",
                  width: "174.4px",
                  height: "38px",
                  margin: "0",
                }}
              >
                {isGreek ? "Φωτογραφίες" : "Gallery"}
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item style={{padding:"0",paddingLeft:"5px"}}>
              <Link
                to="/videos"
                className={`navbar-custom  ${
                  activeLink === "videos"
                    ? 'active-link2 navbar-custom"'
                    : 'navbar-custom"'
                }`}
                style={{ display: "block",width:"174.4px", height:"38px", margin:"0" }}
                onClick={() => handleLinkClick("videos")}
              >
                {isGreek ? "Συνεντεύξεις" : "Interviews"}
              </Link>
            </NavDropdown.Item>


            <NavDropdown.Item style={{padding:"0",paddingLeft:"5px"}}>
              <Link
                to="/videos-DCH"
                className={`navbar-custom  ${
                  activeLink === "videos1"
                    ? 'active-link2 navbar-custom"'
                    : 'navbar-custom"'
                }`}
                style={{ display: "block",width:"174.4px", height:"38px", margin:"0" }}
                onClick={() => handleLinkClick("videos1")}
              >
                {isGreek ? "Videos" : "Videos"}
              </Link>
            </NavDropdown.Item>




            <NavDropdown.Item style={{ padding: "0", paddingLeft: "5px" }}>
              <Link
                to="/bibliography"
                className={`navbar-custom  ${
                  activeLink === "bibliography"
                    ? 'active-link2 navbar-custom"'
                    : 'navbar-custom"'
                }`}
                style={{
                  display: "block",
                  width: "174.4px",
                  height: "38px",
                  margin: "0",
                }}
                onClick={() => handleLinkClick("bibliography")}
              >
                {isGreek ? "Βιβλιογραφία" : "Bibliography"}
              </Link>
            </NavDropdown.Item>
          </NavDropdown>


          {windowWidth >= 768 ? (
          <NavDropdown
            title={isGreek ? "Παιχνίδια" : "Games"}
            id="collasible-nav-dropdown"
            className="navbar-custom"
            style={{ paddingLeft: "8px", paddingRight: "0px" }}
          >
            <NavDropdown.Item style={{ padding: "0", paddingLeft: "5px" }}>
              {windowWidth >= 768 && (
                <Link
                  to="/edu"
                  className={`navbar-custom ${
                    activeLink === "EduGame"
                      ? 'active-link navbar-custom"'
                      : 'navbar-custom"'
                  }`}
                  onClick={() => handleLinkClick("EduGame")}
                  style={{
                    padding: "8px",
                    display: "block",
                    width: "174.4px",
                    height: "38px",
                    margin: "0",
                  }}
                >
                  {isGreek ? "EduGame" : "EduGame"}
                </Link>
              )}
            </NavDropdown.Item>

            <NavDropdown.Item style={{ padding: "0", paddingLeft: "5px" }}>
              <Link
                to="/MainGamePage"
                className={`navbar-custom ${
                  activeLink === "WebGames"
                    ? 'active-link navbar-custom"'
                    : 'navbar-custom"'
                }`}
                onClick={() => handleLinkClick("WebGames")}
                style={{
                  padding: "8px",
                  display: "block",
                  width: "174.4px",
                  height: "38px",
                  margin: "0",
                }}
              >
                {isGreek ? "WebGames" : "WebGames"}
              </Link>
            </NavDropdown.Item>
          </NavDropdown>) : 
          (  
            <Link
                to="/MainGamePage"
                className={`navbar-custom ${
                  activeLink === "WebGames"
                    ? 'active-link navbar-custom"'
                    : 'navbar-custom"'
                }`}
                onClick={() => handleLinkClick("WebGames")}
                style={{
                  padding: "8px",
                  display: "block",
                  width: "174.4px",
                  height: "38px",
                  marginBottom:"3px",
                }}
              >
                {isGreek ? "WebGames" : "WebGames"}
              </Link>
             
            
          )}



        </Nav>
        <Nav>
          <Link
            to="/acknowlegements"
            className={`navbar-custom ${
              activeLink === "Acknowledgements"
                ? 'active-link navbar-custom"'
                : 'navbar-custom"'
            }`}
            onClick={() => handleLinkClick("Acknowledgements")}
            style={{ padding: "8px" }}
          >
            {isGreek ? "Ευχαριστίες" : "Acknowledgements"}
          </Link>

          <Link
            to="/Contact-us"
            className={`navbar-custom ${
              activeLink === "Contact Us"
                ? 'active-link navbar-custom"'
                : 'navbar-custom"'
            }`}
            onClick={() => handleLinkClick("Contact Us")}
            style={{ padding: "8px" }}
          >
            {isGreek ? "Επικοινωνία" : "Contact us"}
          </Link>

          <NavDropdown
            title={isGreek ? "Γλώσσες" : "Languages"}
            id="collasible-nav-dropdown"
            className="navbar-custom"
            style={{ width: "130px", paddingLeft: "8px", marginRight: "30px" }}
          >
            <NavDropdown.Item
              onClick={() => {
                greekLanguage();
                handleLinkClick2("Greek");
              }}
              disabled={isGreek}
              className={`navbar-language nav-dropdown-item ${
                activeLink2 === "Greek" ? 'active-link2 "' : '"'
              }`}
            >
              <img
                style={{ maxWidth: "25px", maxHeight: "20px" }}
                src={flagGreece}
                alt="greekFlag"
              />{" "}
              {isGreek ? "Ελληνικά" : "Greek"}
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => {
                englishLanguage();
                handleLinkClick2("English");
              }}
              disabled={!isGreek}
              className={`navbar-language nav-dropdown-item ${
                activeLink2 === "English" ? 'active-link2 "' : '"'
              }`}
            >
              <img
                style={{ maxWidth: "25px", maxHeight: "20px" }}
                src={flagEngland}
                alt="englandFlag"
              />{" "}
              {isGreek ? "Αγγλικά" : "English"}
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
