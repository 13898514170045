import React from "react";
import { Grid } from "@mui/material";
import ebook_img from "../assets/img/ebook_cover.jpg";
import "../support_folder/ebook.css";
import { useLanguage } from "../hooks/ContextLanguage";
import "../support_folder/marginsSmallScreen.css"
import Loading from "./Loading";
import { useEffect, useState} from "react";

const Ebook = () => {
  const openEnglishEbook = () => {
    window.open("../../Ebooks/English_Ebook/English_version.htm", "_blank");
  };

  const openGreekEbook = () => {
    window.open("../../Ebooks/Greek_Ebook/Greek_version.htm", "_blank");
  };
  
  const { isGreek } = useLanguage();





  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const randomTime = Math.floor(Math.random() * (500 - 100 + 1)) + 500; 
    const timer = setTimeout(() => {
      setLoading(false);
    }, randomTime);

    return () => clearTimeout(timer); 
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <Loading />;
  }







  return (
    
    <Grid
      container
      className="col2"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"30px",
        // minHeight: "800px",
      }}
    >
        <Grid item xl="12" lg="12" md="12" sm="12" xs="12" >
            <h2 style={{textAlign:"center", marginTop:"30px"}}>
             {!isGreek? "Digital Ebooks" :   "Ψηφιακά βιβλία"}     
            </h2>
        </Grid>

      <Grid item className="col1" lg="6" md="6" sm="12" xs="12">
        <Grid
          item
          className=""
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        > 
          <h5 style={{textAlign:"center",marginTop:"30px"}}>{isGreek ? "Κάντε κλικ στην παρακάτω εικόνα για την ελληνική έκδοση του ψηφιακού βιβλίου" : "Click the below image for Greek edition Ebook"}</h5>
          <br />
          <br />
          <br />
          <br />
        </Grid>
        <Grid
          className=""
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop:"30px",
            marginBottom:"20px"
          }}
        >
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front" onClick={openGreekEbook}>
                <img
                  src={ebook_img}
                  alt="Clickable Image"
                  className="img-size"
                />
              </div>
              <div class="flip-card-back" onClick={openGreekEbook}>
                <button style={{ padding: '10px 20px', backgroundColor: '#3498db', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '16px', cursor: 'pointer' }}>
                {isGreek ?  <>
      Άνοιγμα ψηφιακού βιβλίου
      <br />
      (ελληνική έκδοση)
    </>  : "Open E-Book in greek edition"}</button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item className="col1" lg="6" md="6" sm="12" xs="12">
        <Grid
          item
          className=""
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        > 
          <h5 style={{textAlign:"center",marginTop:"30px"}}>{isGreek ? "Κάντε κλικ στην παρακάτω εικόνα για την αγγλικη έκδοση του ψηφιακού βιβλίου" : "Click the below image for English edition Ebook"}</h5>
          <br />
          <br />
          <br />
          <br />
        </Grid>
        <Grid
          className=""
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop:"30px",
            marginBottom:"20px"
          }}
        >
          <div class="flip-card margin-smaller-screen-ebook"  >
            <div class="flip-card-inner">
              <div class="flip-card-front" onClick={openEnglishEbook}>
                <img
                  src={ebook_img}
                  alt="Clickable Image"
                  className="img-size"
                 
                />
              </div>
              <div class="flip-card-back" onClick={openEnglishEbook}>
                <button style={{ padding: '10px 20px', backgroundColor: '#3498db', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '16px', cursor: 'pointer' }}>
                {isGreek ?  <>
      Άνοιγμα ψηφιακού βιβλίου
      <br />
      (αγγλική έκδοση)
    </> : "Open E-Book in english edition"}</button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Ebook;
