import React from "react";
import { Grid } from "@mui/material";
import Saint_Neofytos_Monastery from "../assets/img/Saint_Neophytos/pic52.jpg"
import Englistra from "../assets/img/Saint_Neophytos/pic57.jpg"
import Saint_Neofytos from "../assets/img/Saint_Neophytos/pic93.jpg";
import { Link } from "react-router-dom";
import tepakMnemosyne from "../assets/img/tepakMnemosyne.jpg";
import mnemosyne from "../assets/img/mnemosyne.jpg";
import "../support_folder/maps.css";
import { useLanguage } from "../hooks/ContextLanguage";
import "../support_folder/marginsSmallScreen.css";
import { useState } from "react";
import Unesco_chair from "../assets/img/UNESCO_CHAIR.jpg";
import Department_of_antiquities from "../assets/img/Depantiqu.jpg";
import Cyprus from "../assets/img/Cyprus.jpg";
import "../support_folder/home.css"
import Loading from '../NavigationComponents/Loading'
import { useEffect } from "react";


const Home = () => {

  

  const { isGreek } = useLanguage();


  const [isLoading, setIsLoading] = useState(true);
  const [visibilityCSS,setVisibilityCSS] = useState("hidden")

  const imagesToLoad = [Saint_Neofytos_Monastery, Englistra, Saint_Neofytos, tepakMnemosyne, mnemosyne, Unesco_chair, Department_of_antiquities, Cyprus];
  const [loadedImages, setLoadedImages] = useState(new Array(imagesToLoad.length).fill(false));
  

  

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   imagesToLoad.forEach((src, index) => {
  //     const img = new Image();
  //     img.src = src;
  //     img.onload = () => {
  //       setLoadedImages(prev => {
  //         const newLoadedImages = [...prev];
  //         newLoadedImages[index] = true;
  //         return newLoadedImages;
  //       });
  //     };
  //   });
// }, []);
   
 

  // useEffect(() => {
   
  //   if (loadedImages.every(Boolean)) {
  //     setIsLoading(false);
  //     setVisibilityCSS("visible")
  //   }
  // }, [loadedImages]);


  useEffect(() => {
    window.scrollTo(0, 0);
    const randomTime = Math.floor(Math.random() * (1000 - 500 + 1)) + 500; 
    const timer = setTimeout(() => {
      setIsLoading(false);
      setVisibilityCSS("visible")
    }, randomTime);

    return () => clearTimeout(timer); 
  }, []);
 
  useEffect(() => {
  
    document.body.style.overflow = isLoading ? 'hidden' : 'unset';
  }, [isLoading]);


  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const handleGetDirections = () => {
    if (!navigator.geolocation) {
      alert("You didnt allow us to use your current location");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ latitude, longitude });

        const destLatitude = "34.8464253";
        const destLongitude = "32.4462078";

        const googleMapsDirectionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${destLatitude},${destLongitude}&travelmode=driving`;

        window.open(googleMapsDirectionsUrl, "_blank");
      },
      () => {
        alert("Unable to retrieve your location");
      }
    );
  };

 

 

  return (
    <>
         {isLoading && ( 
       <Loading/>
      )}
 <div style={{ visibility: visibilityCSS }}>



      <Grid container style={{ justifyContent: "center"}}>
        <Grid
          item
          // className="col1"
          style={{ textAlign: "center", marginTop: "30px" }}
        >
          <h1 style={{padding:"5px"}}>
            {isGreek
              ? "Άγιος Νεόφυτος, Μια ολοκληρωμένη προσέγγιση"
              : "Saint Neophytos, A holistic approach"}
          </h1>
          <br />
          <span style={{fontSize:"24px"}}>
            {" "}
            {isGreek
              ? "Καλώς ήρθατε στον ειδικό ιστότοπό μας για τον συνολικό ψηφιακό μετασχηματισμό του"
              : "Welcome to our special website for total digital transformation of"}{" "}
          </span>
          <br />
          <span style={{fontSize:"24px"}}>
            {" "}
            {isGreek
              ? "Άγιου Νεόφυτου στην Πάφο, Κύπρο"
              : "Saint Neophytos in Paphos, Cyprus"}{" "}
          </span>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={4} className="center-right-logos" item>
          <a
            href="http://www.culture.gov.cy/dmculture/DA/DA.nsf/All/F718445CC0501B7DC2257199002ED75A?OpenDocument"
            target="_blank"
          >
            <img
              src={Department_of_antiquities}
              alt="antiquities"
              className="size-of-Depantiqu"
            />
          </a>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>

       
       
          
        </Grid>

        <Grid container xs={12} sm={12} md={12} lg={4} className="center-right-logos">
          <Grid xs={12} sm={12} md={12} lg={12} item>
          
              <h5 style={{ textAlign: "center" }}>
                {isGreek
                  ?  <>
                  ΒΡΕΣ ΤΟΝ ΔΡΟΜΟ ΣΟΥ ΓΙΑ ΤΟ ΜΟΝΑΣΤΗΡΙ ΤΟΥ <br /> ΑΓIOY ΝΕΟΦΥΤΟΥ
                </>
                  : "FIND YOUR WAY TO SAINT NEOPHYTOS MONASTERY"}
              </h5>
           
          </Grid>

          <Grid style={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} item>
            <img style={{ borderRadius: '5px'}}
              
              onClick={handleGetDirections}
              src={Cyprus}
              alt="Cyprus Flag"
              className="size-of-locations"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ marginTop: 10 }}>
        <Grid
          item
          className="col1"
          xs={12}
          sm={12}
          md={12}
          lg={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to={"/SaintNeofytos"} style={{ textDecoration: "none" }}>
          <div class="flip-card-saint-neophytos">
            <div class="flip-card-inner-saint-neophytos">
              <div class="flip-card-front-saint-neophytos">
                <img
                  src={Saint_Neofytos}
                  alt="Clickable Image"
                  className="img-size-saint-neophytos"
                  style={{borderRadius:"20px"}}
                />
              </div>
              <div class="flip-card-back-saint-neophytos">
              <button style={{ width:"300px",padding: '10px 20px', backgroundColor: '#3498db', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '16px', cursor: 'pointer' }}>
                {isGreek ? "Κάντε κλικ εδώ για να μάθετε περισσότερα για τον Άγιο Νεόφυτο" : "Click here to learn more about the Saint Neophytos"}</button>
              </div>
            </div>
          </div>
            
           


          </Link>
        </Grid>

        <Grid
          item
          className="col1"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={12}
          sm={12}
          md={12}
          lg={4}
        >
          <Link
            to={"/SaintNeofytosMonastery"}
            style={{ textDecoration: "none" }}
          >
            <div class="flip-card-saint-neophytos-monastery">
            <div class="flip-card-inner-saint-neophytos-monastery">
              <div class="flip-card-front-saint-neophytos-monastery">
                <img
                  src={Saint_Neofytos_Monastery}
                  alt="Clickable Image"
                  className="img-size-saint-neophytos-monastery"
                  style={{borderRadius:"20px"}}
                />
              </div>
              <div class="flip-card-back-saint-neophytos-monastery">
              <button style={{ width:"300px",padding: '10px 20px', backgroundColor: '#3498db', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '16px', cursor: 'pointer' }}>
                {isGreek ? "Κάντε κλικ εδώ για να μάθετε περισσότερα για το Μοναστήρι" : "Click here to learn more about the Monastery"}</button>
              </div>
            </div>
          </div>


          </Link>
        </Grid>
        <Grid
          item
          className=""
          xs={12}
          sm={12}
          md={12}
          lg={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to={"/Englistra"} style={{ textDecoration: "none" }}>
            
          <div class="flip-card-englistra">
            <div class="flip-card-inner-englistra">
              <div class="flip-card-front-englistra">
                <img
                  src={Englistra}
                  alt="Clickable Image"
                  className="img-size-englistra"
                  style={{borderRadius:"20px"}}
                />
              </div>
              <div class="flip-card-back-englistra">
               
                <button style={{ width:"300px",padding: '10px 20px', backgroundColor: '#3498db', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '16px', cursor: 'pointer' }}>
                {isGreek ? "Κάντε κλικ εδώ για να μάθετε περισσότερα για την Εγκλείστρα" : "Click here to learn more about Engleistra"}</button>
              </div>
            </div>
          </div>
          </Link>
        </Grid>
      </Grid>

      <Grid container style={{ justifyContent: "center" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          className="col1"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <img src={Unesco_chair} alt="tepak" style={{ height: "250px" }} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          className="col1"
          style={{ textAlign: "center", marginTop: "50px" }}
        >
          <iframe
            className="size-map-home"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1299.625090836488!2d32.44560951721626!3d34.84633965924992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e708d356432945%3A0xbb28a97c2d659d43!2sHoly%20Monastery%20of%20Saint%20Neophytos%20the%20Recluse!5e1!3m2!1sen!2s!4v1704542965504!5m2!1sen!2s"
            height={500}
            style={{ border: "0" }}
            allowfullscreen
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          className="col1 right-logos-home"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="col1 center-right-logos"
          >
            <img
              src={tepakMnemosyne}
              className="margins-for-smaller-screens size-of-logos-right"
              alt="tepak"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="col1 center-right-logos"
          >
            <a href="https://erachair-dch.eu/portfolio/the-hermitage-of-saint-neophytos/" target="_blank">
            <img
              className="margins-for-smaller-screens size-of-logos-right"
              src={mnemosyne}
              alt="mnemosyne"
            />
            </a>
          </Grid>
        </Grid>
      </Grid>
      </div>
    </>
  );
};

export default Home;
