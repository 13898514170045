import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import NavigationBar from './layout/NavigationBar';
import Footer from './layout/Footer';
import Home from './layout/Home';
import Ebook from './NavigationComponents/Ebook';
import PhotoGallery from './NavigationComponents/PhotoGallery';
import VideoGallery from './NavigationComponents/VideoGallery';
import Contact_us from './NavigationComponents/Contact_us';
import Education from './NavigationComponents/Education';
import GameLVL1 from './NavigationComponents/Games/GameLVL1';
import GameLVL2 from './NavigationComponents/Games/GameLVL2';
import GameLVL3 from './NavigationComponents/Games/GameLVL3';
import MainGamePage from './NavigationComponents/Games/MainGamePage';
import Acknowledgements from './NavigationComponents/Acknowlegements';
import SaintNeofytos from './NavigationComponents/SaintNeofytos';
import SaintNeofytoMonastery from './NavigationComponents/SaintNeofytoMonastery';
import Englistra from './NavigationComponents/Englistra';
import Bibliography from './NavigationComponents/Bibliography';
import View360 from './NavigationComponents/View360';
import VideosYoutube from './NavigationComponents/VideosYoutube';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import useWindowWidth from './hooks/useWindowWidth';


function App() {

  const windowWidth = useWindowWidth();



  return (
    <BrowserRouter>
      <Box className="app-container">
        <NavigationBar />
        <Box component="main" className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/e-book" element={<Ebook />} />
            <Route path="/photos" element={<PhotoGallery />} />
            <Route path="/videos" element={<VideoGallery />} />
            <Route path="/videos-DCH" element={<VideosYoutube />} />
            <Route path="/bibliography" element={<Bibliography />} />
            <Route path="/contact-us" element={<Contact_us />} />
            {windowWidth >= 768 && <Route path="/edu" element={<Education />} />}
            <Route path="/MainGamePage" element={<MainGamePage />} />
            <Route path="/games/lvl1" element={<GameLVL1 />} />
            <Route path="/games/lvl2" element={<GameLVL2 />} />
            <Route path="/games/lvl3" element={<GameLVL3 />} />
            <Route path="/acknowlegements" element={<Acknowledgements />} />
            <Route path="/saintNeofytos" element={<SaintNeofytos />} />
            <Route path="/saintNeofytosMonastery" element={<SaintNeofytoMonastery />} />
            <Route path="/englistra" element={<Englistra />} />
            <Route path="/360View" element={<View360 />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </BrowserRouter>
  );
}

export default App;
