import React from "react";
import { Grid } from "@mui/material";
import Saint_Engleistra from "../assets/img/Saint_Neophytos/pic57.jpg"
import { useLanguage } from "../hooks/ContextLanguage";
import {Button} from "@mui/material";
import { Link } from "react-router-dom";
import "../support_folder/Saint_Neophytos_Engleitra_And_Monastery.css"
import { useEffect } from "react";
const Englistra = () => {
  const { isGreek } = useLanguage();

 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <Grid
      container
      className="col1"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Grid item style={{  display: "flex",
 justifyContent: "center",
 marginTop: "50px",
 boxShadow:  "rgba(0, 0, 0, 0.35) 0px -50px 180px -28px inset",
 borderRadius:"50px"   }} sm={7} md={7} lg={7}>
           <img
           className="change_photo_size"
          src={Saint_Engleistra}
          alt="Saint Neofytos"
          style={{
            
            maxHeight: "750px",
            borderRadius: "20px",marginTop:"25px"
            ,marginBottom:"25px",
          }}
        />
      </Grid>
      <Grid
        className="col2"
        style={{ marginTop: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <h3 className="textInCenter">{isGreek ? `Σχετικά με την Εγκλείστρα` : `About Engleistra`}</h3>
      </Grid>
      <Grid
        className="col2"
        style={{
          marginTop: "15px",
          textAlign: "justify",
          marginBottom: "70px",
        }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <p style={{ margin: "0px" }} className="padding-for-smaller-screens">
          {isGreek
            ? ` 
            Οι τοιχογραφίες στους τοίχους της Εγκλείστρας του Αγίου Νεοφύτου, είναι μερικά από τα πιο σημαντικά Κυπριακά γνωστά δείγματα βυζαντινής τέχνης του 12ου αιώνα.
                        Οι επισκέπτες καθώς και οι μελετητές/ακαδημαϊκοί εντυπωσιάζονται από τις σπάνιες σωζόμενες ζωγραφιές εξαιρετικής καλλιτεχνικής ποιότητας και την αξιοσημείωτη βραχοτομημένη
                         δομή της Εγκλείστρας. Μεταξύ των σκηνών που παρουσιάζονται είναι η Σταύρωση η οποία ζωγραφίστηκε αρκετές φορές σε διάφορες τοποθεσίες σε όλη την Εγκλείστρα.
                         μαζί με τον Ευαγγελισμό, τη Δέηση και διάφορες σκηνές των Παθών, καθεμιά από τις οποίες αποτυπώνει σκηνές απο την Βίβλο με σημαντική λεπτομέρεια.
             `
            : `The paintings on the walls of St. Neophytos Engleistra are some of the most significant Cyprus well known examples of Byzantine painitngs in the 12 century.
            The visitors as well as pligirms and academics are impressed by the rare surviving paintings of exceptional artistic quality and the remarkable rock-cut
             structure of the Engleistra. Among the scenes shown is the Crucifixion which was painted several times in various locations across the Engleistra
             alongside the Annunciation, the Deesis, and various Passion scenes, each capturing key biblical moments with vivid detail.  
            ​`}
        </p>
      </Grid>

      <Grid
        className="col2"
        style={{
          marginTop: "15px",
          textAlign: "justify",
          marginBottom: "70px",
        }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <p style={{ margin: "0px" }} className="padding-for-smaller-screens">
          {isGreek
            ? ` 
            Ωστόσο, ένα από τα πιο σημαντικά και εντυπωσιακά σκηνικά είναι ζωγραφισμένο στη δυτική πλευρά του ανατολικού κόλπου
                        , όπου μπορεί να βρεθεί μια εκπληκτική εικόνα των προσδοκιών του μοναχού. Οι αρχάγγελοι Μιχαήλ και Γαβριήλ
                         απεικονίζονται να κρατούν τον Άγιο Νεόφυτο από τους ώμους. Η εικόνα επεξηγείται με μια επιγραφή που λέει:
                          "Προσεύχομαι θερμά να μπορέσω πράγματι να εγγραφώ μεταξύ των αγγέλων χάρης την ενάρετη συνήθεια μου`
            : ` However, one of the most peculiar and striking sceneries is painted on the west face of the east bay
            , where an astonishing picture of the monk's aspirations can be found. The archangels Michael and Gabriel
             are shown holding St. Neophytos by the shoulders. The image is explained with an inscription that reads,
              "I fervently pray that I may indeed be enrolled among the angels by virtue of my habit" 
            ​`}
        </p>
      </Grid>


      <Grid
        className="col2"
        style={{
          marginTop: "15px",
          textAlign: "justify",
          marginBottom: "70px",
        }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <p style={{ margin: "0px" }} className="padding-for-smaller-screens">
          {isGreek
            ? `Επιπλέον, η Εγκλείστρα του Αγίου Νεοφύτου αποτελείται από τρία δωμάτια και τον νάρθηκα. Ο νάρθηκας είναι ένα αρχιτεκτονικό στοιχείο
             που συναντάται στις χριστιανικές εκκλησίες, συμπεριλαμβανομένης της Εγκλείστρας. Είναι ένας μερικώς κλειστός χώρος και επίσης είναι
              το πρώτο δωμάτιο που συναντάμε. Η δομή της Εγκλείστρας είναι ο νάρθηκας, από τον οποίο μπορείτε να εισέλθετε στο δεύτερο δωμάτιο, το
               οποίο είναι ο ναός. Αυτό το δωμάτιο οδηγεί στο Βήμα, το μεσαίο δωμάτιο που συνδέει το ναό με το τρίτο δωμάτιο, το οποίο είναι το κελί του Αγίου Νεοφύτου.`

            : `Furthermore, the Engleistra of Saint Neophytos consists of three rooms and the narthex. The narthex,
             an architectural element found in Christian churches, including the Engleistra, serves as the first room you encounter. 
             From the narthex, you enter the second room, known as the naos. The naos leads to the bema, a middle room connecting
              the naos to the third room, which is the cell of Saint Neophytos." 
            ​`}
        </p>
      </Grid>

      <Grid
        className="col2"
        style={{ display:"flex",justifyContent:"center", marginBottom: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
       <Button
          variant="contained"
          component={Link}
          to="/"
          sx={{
            marginTop: "30px",
            width: "250px",
            color: "white",
            textDecoration: "none",
            textTransform: 'none',
           
            "&:hover": {
              color: "white",
              textDecoration: "none",
            },
          }}
        >
          {isGreek ? "ΠΙΣΩ ΣΤΗΝ ΑΡΧΙΚΗ ΣΕΛΙΔΑ" : "BACK TO HOME PAGE"}
        </Button>

      </Grid>

      
    </Grid>
  );
};

export default Englistra;
