import React, { createContext, useState, useContext } from 'react';


const LanguageContext = createContext();


export const LanguageProvider = ({ children }) => {
  const [isGreek, setIsGreek] = useState(false); 

  
  const greekLanguage = () => {
    setIsGreek(true);
  };

  const englishLanguage = () =>
  {
    setIsGreek(false);
  }

  return (
    <LanguageContext.Provider value={{ isGreek, greekLanguage, englishLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the language context
export const useLanguage = () => useContext(LanguageContext);
