import React from "react";
import { Grid, Hidden } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Margin } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

import "../support_folder/maps.css";
import "../support_folder/socialMedia.css";
import Swal from 'sweetalert2'
import {
  faTwitter,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useLanguage } from "../hooks/ContextLanguage";
import "../support_folder/marginsSmallScreen.css";

// import {CardActions, CardContent, CardHeader,Divider}  from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";

import emailjs from '@emailjs/browser';
import Loading from "./Loading" 

const Contact_us = () => {

 

  const { isGreek } = useLanguage();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isNameSurEmpty, setIsNameSurEmpty] = useState(false);
  const [isSubjectEmpty, setIsSubjectEmpty] = useState(false);
  const [isMessageEmpty, setIsMessageEmpty] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const [nameTouched, setNameTouched] = useState(false);
  const [subjectTouched, setSubjectTouched] = useState(false);
  const [messageTouched, setMessageTouched] = useState(false);


  const randomGeratorOfString = Math.random().toString(36).slice(8);

  const [captchaText, setCaptchaText] = useState(randomGeratorOfString);

  const [captchCorrect,setCaptchaCorrect] = useState(false);
  const [valid , setValid] = useState(false);
  const [textFieldCaptcha, setTextFieldCaptcha] = useState("")

  const [visibilityCSS,setVisibilityCSS] = useState("hidden")

  const refreshTheCaptchaString = ()=>
  {
    setCaptchaText(Math.random().toString(36).slice(8));
  }





  useEffect(() => {
    const nameNotEmpty = name.trim() !== "";
    const subjectNotEmpty = subject.trim() !== "";
    const messageNotEmpty = message.trim() !== "";

    if (nameTouched) {
      setIsNameSurEmpty(!nameNotEmpty);
    }
    if (subjectTouched) {
      setIsSubjectEmpty(!subjectNotEmpty);
    }
    if (messageTouched) {
      setIsMessageEmpty(!messageNotEmpty);
    }

    setIsFormValid(
      isValidEmail && nameNotEmpty && subjectNotEmpty && messageNotEmpty 
    );
  }, [
    name,
    email,
    subject,
    message,
    nameTouched,
    subjectTouched,
    messageTouched,
    isValidEmail,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isCaptchaCorrect = textFieldCaptcha === captchaText;

    console.log("capCorrect");
    console.log(captchCorrect);
   
    if (isFormValid && isCaptchaCorrect) {

      const formData = {
        name: name,
        email: email,
        subject: subject,
        message: message,
      };

      

    
      

      try {


        emailjs.send('service_7hkjnhs', 'template_koigr8k',formData,"Ky5DGoB3lZDNDbEnq").then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );


      //   await fetch("http://38.242.145.70:8081/contact-us", {  
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(formData),
      //   }
      // );

        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setTextFieldCaptcha("");
        refreshTheCaptchaString();

        setIsValidEmail(false);
        setIsNameSurEmpty(false);
        setIsSubjectEmpty(false);
        setIsMessageEmpty(false);
        setEmailTouched(false);
        setNameTouched(false);
        setSubjectTouched(false);
        setMessageTouched(false);


        Swal.fire({
          icon: 'success',
          title: 'Success!!',
          text: 'Your Email has been sent successfully.',
        
        });


        console.log("Form data sent successfully");
      } catch (error) {
        
        // Handle errors
        console.error("Error:");
        console.log(error);
      }

    }
    else
    {

        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setTextFieldCaptcha("");
        refreshTheCaptchaString();

        setIsValidEmail(false);
        setIsNameSurEmpty(false);
        setIsSubjectEmpty(false);
        setIsMessageEmpty(false);
        setEmailTouched(false);
        setNameTouched(false);
        setSubjectTouched(false);
        setMessageTouched(false);

      Swal.fire({
        icon: 'error', 
        title: 'Error',
        text: 'You put wrong captcha please try again'
      });
    }


  };

  const handleEmailBlur = (e) => {
    setEmailTouched(true);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailPattern.test(email);

    setIsValidEmail(isValid);
  };

  const handleSubjectBlur = () => {
    setIsSubjectEmpty(subject.trim() === "");
  };

  const handleNameSurBlur = () => {
    setIsNameSurEmpty(name.trim() === "");
  };

  const handleMessageBlur = () => {
    setIsMessageEmpty(message.trim() === "");
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailPattern.test(email);

    setIsValidEmail(isValid);
  };

  const handleNameSurChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    setIsNameSurEmpty(newName.trim() === "");
  };

  const handleSubjectChange = (e) => {
    const newSubject = e.target.value;
    setSubject(newSubject);
    setIsSubjectEmpty(newSubject.trim() === "");
  };

  const hanldeMessageChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    setIsMessageEmpty(newMessage.trim() === "");
  };

  const [isLoading, setIsLoading] = useState(false);

  

  useEffect(() => {
    window.scrollTo(0, 0);
    const randomTime = Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000; 
    const timer = setTimeout(() => {
      setIsLoading(true);
      setVisibilityCSS("visible")
    }, randomTime);
  return () => clearTimeout(timer); 
  }, []);

    
  // const handleLoad = () => {
    
  //   setIsLoading(true);
  //   setVisibilityCSS("visible");
  // };


  
 

  // useEffect(() => {
  //   if (isLoading) {
      
  //     document.body.style.overflow = 'hidden';
  //   } else {
     
  //     document.body.style.overflow = 'unset';
  //   }

  //   return () => {
  //     document.body.style.overflow = 'unset';
  //   };
  // }, [isLoading]);





  
  useEffect(() => {
  
    document.body.style.overflow = !isLoading ? 'hidden' : 'unset';
  }, [isLoading]);


  return (
    <>
       
     
     
      {!isLoading && ( 
        <Loading/>
      )}
      <div style={{ visibility: visibilityCSS }}>

  
      <Grid container className="col2" mt={5} >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <h2 className="textAlign-small-screen">
            {isGreek ? "Επικοινωνήστε μαζί μας" : "Contact us"}
          </h2>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <h4 className="textAlign-small-screen">
            {isGreek
              ? "αν έχετε οποιεσδήποτε ερωτήσεις"
              : "if you have any questions"}
          </h4>
        </Grid>
      </Grid>

      <Grid container className="col2">
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="col1"
            mt={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FontAwesomeIcon
              icon={faHouse}
              style={{ marginTop: "3px", marginRight: "3px" }}
            />
            <p className="textAlign-small-screen" style={{ margin: "0px" }}>
              Arch. Kyprianou 31, 3036 Limassol, Cyprus | Cyprus University of
              Technology
            </p>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="col1"
            mt={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FontAwesomeIcon
              icon={faPhone}
              style={{ marginTop: "3px", marginRight: "3px" }}
            />
            <p className="textAlign-small-screen" style={{ margin: "0px" }}>
              +357-25002020
            </p>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="col1"
            mt={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ marginTop: "5px", marginRight: "3px" }}
            />
            <p className="textAlign-small-screen" style={{ margin: "0px" }}>
              mnemosyne@cut.ac.cy
            </p>

         
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="col1"
            mt={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <iframe
              className="size-map-contact-us"
              title="HOST_Google_Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.1868377840215!2d33.041762476367246!3d34.67523368457787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e7330344bfffff%3A0xd8ad824d7ef9c378!2sUNESCO%20Chair%20on%20Digital%20Cultural%20Heritage%20%2F%20Digital%20Heritage%20Research%20Lab!5e0!3m2!1sen!2s!4v1694883873357!5m2!1sen!2s"
              height={350}
              style={{ border: "0" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
             
            
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="col1"
            mt={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            
            <a
              href="https://twitter.com/UNESCO_DCH_ERA/"
              className="social-icon"
            >
              
              <FontAwesomeIcon icon={faTwitter} size="2x" id="twitterIcon" />
            </a>
            <a
              href="https://www.facebook.com/Unesco.DCH"
              className="social-icon"
            >
              <FontAwesomeIcon icon={faFacebook} size="2x" id="facebookIcon" />
            </a>
            <a
              href="https://www.youtube.com/@DigitalHeritageResearchLab"
              className="social-icon"
            >
              <FontAwesomeIcon icon={faYoutube} size="2x" id="youtubeIcon" />
            </a>
          </Grid>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12} className="col1" mt={2}>
          <Grid
            item
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
            mt={2}
          >
            <TextField
              style={{ width: "80%" }}
              label={
                isGreek ? "Το όνομά σας/το επώνυμό σας" : "Your Name/Surname"
              }
              value={name}
              onChange={handleNameSurChange}
              onBlur={() => setNameTouched(true)}
              error={isNameSurEmpty}
              helperText={isNameSurEmpty ? "Name/Surname cannot be empty" : ""}
            />
          </Grid>
          <Grid
            item
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
            mt={2}
          >
            <TextField
              className="mt-4"
              style={{ width: "80%" }}
              label={isGreek ? "Το email σας" : "Your Email"}
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              error={!isValidEmail && emailTouched}
              helperText={
                !isValidEmail && emailTouched ? "Invalid email address" : ""
              }
            />
          </Grid>
          <Grid
            item
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
            mt={2}
          >
            <TextField
              className="mt-4"
              style={{ width: "80%" }}
              label={isGreek ? "Θέμα" : "Subject"}
              value={subject}
              onChange={handleSubjectChange}
              onBlur={() => setSubjectTouched(true)}
              error={isSubjectEmpty}
              helperText={isSubjectEmpty ? "Subject cannot be empty" : ""}
            />
          </Grid>
          <Grid
            item
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
            mt={2}
          >
            <TextField
              className="mt-4"
              style={{ width: "80%" }}
              label={isGreek ? "Μήνυμα" : "Message"}
              value={message}
              onChange={hanldeMessageChange}
              onBlur={() => setMessageTouched(true)}
              error={isMessageEmpty}
              helperText={isMessageEmpty ? "Message cannot be empty" : ""}
            />
          </Grid>


          <Grid
            item
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
            mt={2}
          >
            <TextField
              className="mt-4 ml-1"
              style={{ width: "30%" }}
              label={isGreek ? "Enter Captcha" : "Enter Captcha"}
              value={textFieldCaptcha}
              onChange={(e)=>setTextFieldCaptcha(e.target.value)}
              error={valid}
              helperText={valid ? "Invalid" : ""}
            />
          </Grid>

          <Grid
            item
            lg={12}
            style={{ display: "flex", justifyContent: "center",alignItems:"center" }}
            mt={2}
          >
           <div style={{display: "flex", justifyContent: "center",alignItems:"center", marginLeft:"19px"}}>
           <div style={{ 
                  color: '#D3D3D3',
                  fontSize: '16px', 
                  fontWeight: 'bold', 
                  padding: '1px', 
                  backgroundColor: 'black', 
                  border: '1px solid #ccc', 
                  borderRadius: '5px', 
                  textAlign: 'center', 
                  width: '70px', 
}}>
    {captchaText}
</div>
              <Button  size="small" style={{
    minWidth: '40px',
    height: '30px'  
  }} onClick={() => refreshTheCaptchaString()}><RefreshIcon /></Button>
            </div>
          
          </Grid>
          <Grid
            item
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
            mt={2}
          >
            <Button
              onClick={handleSubmit}
              className="margins-for-smaller-screens"
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isFormValid} // Disable the button if not all fields are valid
            >
              {isGreek ? "Υποβολη" : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      </div>
      {/* </>)} */}
    </>
  );
};

export default Contact_us;
