import React from "react";
import { Grid } from "@mui/material";
import euFlag from "../assets/img/european-flag.jpg";
import { useLanguage } from "../hooks/ContextLanguage";
import Loading from "./Loading";
import { useState,useEffect } from "react";

const Acknowlegements = () => {


  const { isGreek } = useLanguage();

  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const randomTime = Math.floor(Math.random() * (200 - 100 + 1)) + 500; 
    const timer = setTimeout(() => {
      setLoading(false);
    }, randomTime);

    return () => clearTimeout(timer); 
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid
      container
      className="col1"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Grid className="col2" sm={8} md={8} lg={8} item>
        <h3 style={{ marginTop: "70px" }}>{!isGreek? `ACKNOWLEDGEMENTS`:`ΕΥΧΑΡΙΣΤΙΕΣ`}</h3>
      </Grid>
      <Grid
        className="col2"
        style={{ marginTop: "15px", textAlign: "justify" }}
        sm={8}
        md={8}
        lg={8}
        item
      >
        <p style={{ margin: "0px", marginTop:"20px", fontSize:"18px" }} className="padding-for-smaller-screens">
          {!isGreek ? `I want to thank my supervisor Dr. Marinos Ioannides, for his valuable
          guideness in my bachelor degree Thesis for my final year at the
          Technological University of Cyprus he is contribution was crucial for
          the project, as well as he made me appreciate the history and the
          ancient knowledge of our ancestors, also he made me realize the
          importance of our heritage because is ours knowledge (gnosis). I also
          want to thank my co Supervisor Mr. Neofytos who helped me with the
          creation of the unity game and his advice about the animations. Also
          he gave me suggestions about the design of the website. Furthermore, I
          want to the thank Mr. Orestis Rizopoulos which help me a lot about the
          topics of the website and also with the creation of the Digital
          E-book. Additionally, i want to the Thanks Mrs. Andrea which gave me
          the Assets like the photos and videos that I use in my website. Furthemore i wanna thank Mr. Panagiotis Panayiotou that fixed some flaws that were in the 3D model that i used in my unity game.
          Additionally.Finally, I want to thank the entire team on
          the UNESCO Chair on Digital Cultural Heritage which help me through
          all the process of the creation of this Digital Website of our
          heritage.` : `Θέλω να ευχαριστήσω τον επιβλέποντα καθηγητή μου Δρ Μαρίνο Ιωαννίδη, για την πολύτιμη
          καθοδήγησή του στην πτυχιακή μου εργασία για το τελευταίο έτος σπουδών μου στο
        Τεχνολογικό Πανεπιστήμιο Κύπρου, η συμβολή του ήταν καθοριστική
          για την εργασία, καθώς με έκανε να εκτιμήσω την ιστορία και την
        την αρχαία γνώση των προγόνων μας, επίσης με έκανε να συνειδητοποιήσω την
        τη σημασία της κληρονομιάς μας. Εγώ επίσης
        θέλω να ευχαριστήσω τον συνεπιβλέποντα μου κ. Νεόφυτο που με βοήθησε με την
        δημιουργία του παιχνιδιού unity και τις συμβουλές του σχετικά με τα κινούμενα σχέδια. Επίσης
          μου έδωσε υποδείξεις σχετικά με το σχεδιασμό της ιστοσελίδας. Επιπλέον,
        θέλω να ευχαριστήσω τον κ. Ορέστη Ριζόπουλο που με βοήθησε πολύ σχετικά με το
        θέματα της ιστοσελίδας και επίσης με τη δημιουργία του
          Ηλεκτρονικού Βιβλίου. Επιπλέον, θέλω να ευχαριστήσω την κ. Ανδρέα που μου έδωσε
          τα Assets όπως οι φωτογραφίες και τα βίντεο που χρησιμοποιώ στην ιστοσελίδα μου. 
          Ακόμη θέλω να ευχαριστήσω τον κ. Παναγιώτη Παναγιώτου που έφτιαξε κάποιες ατέλειες που υπήρχαν στο στο στο τρισδιάστατο μοντέλο που χρησιμοποίησα στο unity παιχνίδι μου.
         Τέλος, θέλω να ευχαριστήσω όλη την ομάδα της
         έδρας της UNESCO Chair On Digital Cultural Heritage που με βοήθησε καθ' όλη τη διάρκεια δημιουργίας αυτής της ψηφιακής ιστοσελίδας.`}
        </p>
      </Grid>

      <Grid
        className="col2"
        style={{ textAlign: "justify", marginTop: "40px"  }}
        sm={8}
        md={8}
        lg={8}
        container
      >
        <Grid item
          className="col2 center-div-small-screen"
          style={{ marginTop: "75px", textAlign: "justify" }}
          xs={12}
          sm={12}
          md={12}
          lg={3}
          xl={2.4}
        
        >
          <img
            style={{ width: "200px", height: "150px"}}
            src={euFlag}
            alt=""
          />
        </Grid>

        <Grid item
          className="col2"
          style={{ marginTop: "75px", textAlign: "justify", marginBottom:"20px" }}
          xs={12}
          sm={12}
          md={12}
          lg={9}
          xl={9.6}
        >
          <p style={{margin:"0px",fontSize:"18px"}} className="padding-for-smaller-screens">
          <a href="https://erachair-dch.eu" target="_BLANK">
          <strong>{!isGreek? `MNEMOSYNE` : `ΜΝHΜΟΣΥΝΗ`}  </strong> 
          </a>
          {!isGreek ? `project has received funding from the European Union’s H2020 Framework. Programme for research and innovation under Grant agreement no. 810857` :
           `έχει λάβει χρηματοδότηση από το πλαίσιο H2020 της Ευρωπαϊκής Ένωσης. Πρόγραμμα για την έρευνα και την καινοτομία στο πλαίσιο της συμφωνίας επιχορήγησης αριθ. 810857.`}
          
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};


export default Acknowlegements;
