import React from 'react'
import { Grid } from "@mui/material";
import { useState,useEffect } from 'react';
import Loading from './Loading';
const Education = () => {


  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const randomTime = Math.floor(Math.random() * (500 - 100 + 1)) + 500; 
    const timer = setTimeout(() => {
      setLoading(false);
    }, randomTime);

    return () => clearTimeout(timer); 
  }, []);

  if (loading) {
    return <Loading />;
  }



  return (
    <Grid container>
      <Grid
          sm={12}
          md={12}
          lg={12}
          
          item
          style={{display:"flex",justifyContent:"center", marginTop: "10px" }}
        >
      <iframe
        title="Unity WebGL Game"
        src='/index1.html'
        width="1000"
        height="800"
        frameBorder="0"
      />
        </Grid>
   </Grid>
  )
}

export default Education
