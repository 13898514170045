import React from "react";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import { useState, useEffect } from "react";

import { Modal, Grid } from "@mui/material";

import "react-lazy-load-image-component/src/effects/blur.css";
import "../support_folder/lazy-load.css";

import Loading from "./Loading";
import "../support_folder/slider.css";


import pic1 from "../assets/img/pic1.jpg";
import pic2 from "../assets/img/pic2.jpg";

import pic4 from "../assets/img/pic4.jpg";
import pic5 from "../assets/img/pic5.jpg";

import pic9 from "../assets/img/pic9.jpg";

import pic11 from "../assets/img/pic11.jpg";
import pic12 from "../assets/img/pic12.jpg";
import pic13 from "../assets/img/pic13.jpg";
import pic14 from "../assets/img/pic14.jpg";
import pic15 from "../assets/img/pic15.jpg";
import pic16 from "../assets/img/pic16.jpg";
import pic17 from "../assets/img/pic17.jpg";
import pic18 from "../assets/img/pic18.jpg";
import pic19 from "../assets/img/pic19.jpg";
import pic20 from "../assets/img/pic20.jpg";
import pic21 from "../assets/img/pic21.jpg";
import pic22 from "../assets/img/pic22.jpg";
import pic23 from "../assets/img/pic23.jpg";
import pic24 from "../assets/img/pic24.jpg";
import pic25 from "../assets/img/pic25.jpg";
import pic26 from "../assets/img/pic26.jpg";

import pic28 from "../assets/img/pic28.jpg";
import pic29 from "../assets/img/pic29.jpg";

import pic31 from "../assets/img/pic31.jpg";
import pic32 from "../assets/img/pic32.jpg";

import pic34 from "../assets/img/pic34.jpg";

import pic3S from "../assets/img/Saint_Neophytos/pic3.jpg";

import pic11S from "../assets/img/Saint_Neophytos/pic11.jpg";

import pic13S from "../assets/img/Saint_Neophytos/pic13.jpg";



import pic17S from "../assets/img/Saint_Neophytos/pic17.jpg";
import pic18S from "../assets/img/Saint_Neophytos/pic18.jpg";

import pic21S from "../assets/img/Saint_Neophytos/pic21.jpg";
import pic22S from "../assets/img/Saint_Neophytos/pic22.jpg";
import pic23S from "../assets/img/Saint_Neophytos/pic23.jpg";

import pic27S from "../assets/img/Saint_Neophytos/pic27.jpg";
import pic28S from "../assets/img/Saint_Neophytos/pic28.jpg";

import pic32S from "../assets/img/Saint_Neophytos/pic32.jpg";
import pic33S from "../assets/img/Saint_Neophytos/pic33.jpg";
import pic34S from "../assets/img/Saint_Neophytos/pic34.jpg";
import pic35S from "../assets/img/Saint_Neophytos/pic35.jpg";

import pic37S from "../assets/img/Saint_Neophytos/pic37.jpg";
import pic38S from "../assets/img/Saint_Neophytos/pic38.jpg";


import pic41S from "../assets/img/Saint_Neophytos/pic41.jpg";
import pic42S from "../assets/img/Saint_Neophytos/pic42.jpg";


import pic49S from "../assets/img/Saint_Neophytos/pic49.jpg";
import pic50S from "../assets/img/Saint_Neophytos/pic50.jpg";

import pic58S from "../assets/img/Saint_Neophytos/pic58.jpg";


import pic61S from "../assets/img/Saint_Neophytos/pic61.jpg";
import pic62S from "../assets/img/Saint_Neophytos/pic62.jpg";
import pic63S from "../assets/img/Saint_Neophytos/pic63.jpg";
import pic64S from "../assets/img/Saint_Neophytos/pic64.jpg";

import pic66S from "../assets/img/Saint_Neophytos/pic66.jpg";

import pic69S from "../assets/img/Saint_Neophytos/pic69.jpg";

import pic71S from "../assets/img/Saint_Neophytos/pic71.jpg";

import pic75S from "../assets/img/Saint_Neophytos/pic75.jpg";

import pic82S from "../assets/img/Saint_Neophytos/pic82.jpg";

import pic87S from "../assets/img/Saint_Neophytos/pic87.jpg";

import pic93S from "../assets/img/Saint_Neophytos/pic93.jpg";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { useLanguage } from "../hooks/ContextLanguage";
const PhotoGallery = () => {
  const { isGreek } = useLanguage();

  const itemData = [
    { id: 1, img: pic1, height: 600, info: "Location: Engleistra, Bema (3rd room)", category: "Iconography"},

    { id: 4, img: pic4, height: 450,info: "Location: Engleistra, Naos (2nd room)", category: "Iconography"},
    { id: 5, img: pic5, height: 450, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },

    { id: 11, img: pic11, height: 700,info: "Location: Engleistra, Narthex (1st room)", category: "Iconography" },
    { id: 12, img: pic12, height: 450, info: "Location: Engleistra, Narthex (1st room)", category: "Iconography" },
    { id: 13, img: pic13, height: 700, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 14, img: pic14, height: 500, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 15, img: pic15, height: 450, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 16, img: pic16, height: 450, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 17, img: pic17, height: 600, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 18, img: pic18, height: 450, info: "Location: Engleistra, Bema (3rd room)", category: "Iconography" },
    { id: 19, img: pic19, height: 450, info: "Location: Engleistra, Bema (3rd room)", category: "Iconography" },
    { id: 20, img: pic20, height: 700, info: "Location: Engleistra, Bema (3rd room)", category: "Iconography" },
    { id: 21, img: pic21, height: 450, info: "Location: Engleistra, Bema (3rd room)", category: "Iconography" },
    { id: 22, img: pic22, height: 450, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 23, img: pic23, height: 450, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 24, img: pic24, height: 450, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 25, img: pic25, height: 450, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 26, img: pic26, height: 450, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 28, img: pic28, height: 450, info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },
    { id: 29, img: pic29, height: 450,  info: "Location: Engleistra, Naos (2nd room)", category: "Iconography" },


    { id: 34, img: pic34, height: 450, category: "Engleistra", info: "Entrance to the 4th room of Engelistra (the cell)" },
    { id: 2, img: pic2, height: 450, category: "Engleistra", info: "Engleistra, Saint Neophytos cell (4th room)"},
    { id: 31, img: pic31, height: 450, category: "Monastery", info: "Monastery view from the Narthex of Engleistra" },

    { id: 32, img: pic32, height: 450, category: "Monastery" , info: "Side view from the Narthex of Engleistra" },
    { id: 9, img: pic9, height: 450, category: "Monastery", info: "Saint Neophytos Monastery" },
    { id: 41, img: pic3S, height: 450, category: "Monastery", info: "Entrance of the Monastery of Saint Neophytos" },

    { id: 49, img: pic11S, height: 450, category: "Monastery", info: "Monastery view from the main square" },
    { id: 51, img: pic13S, height: 450, category: "Monastery",info: "Main square of the Monastery" },

    
    { id: 55, img: pic17S, height: 450, category: "Monastery" ,info: "Saint Neophytos Monastery" },
    { id: 56, img: pic18S, height: 450, category: "Monastery" ,info: "Saint Neophytos Monastery" },

    { id: 59, img: pic21S, height: 450, category: "Monastery" ,info: "Saint Neophytos Monastery" },
    { id: 60, img: pic22S, height: 450, category: "Monastery" ,info: "Saint Neophytos Monastery" },
    { id: 61, img: pic23S, height: 450, category: "Monastery" ,info: "Saint Neophytos Monastery" },

    { id: 65, img: pic27S, height: 450, category: "Monastery", info: "Monastery museum" },

 
    { id: 70, img: pic32S, height: 450, category: "Monastery" , info: "Location: Near the bridge that connects the main square with the Engleistra of Saint Neophytos" },
    { id: 66, img: pic28S, height: 450, category: "Monastery", info:"Inside the ΑΓΙΑΣΜΑ (upstairs)" },

    { id: 71, img: pic33S, height: 450, category: "Monastery" , info:"Inside the ΑΓΙΑΣΜΑ (downstairs)" },
    { id: 72, img: pic34S, height: 450, category: "Monastery", info:"Inside the ΑΓΙΑΣΜΑ (main room)"   },
    { id: 73, img: pic35S, height: 450, category: "Monastery", info:"Inside the ΑΓΙΑΣΜΑ (main room)"  },

 
    { id: 75, img: pic37S, height: 450, category: "Monastery", info:"Inside the ΑΓΙΑΣΜΑ (main room)"  },
  

    { id: 76, img: pic38S, height: 450, category: "Monastery", info:"Inside the ΑΓΙΑΣΜΑ (main room)" },
    { id: 79, img: pic41S, height: 450, category: "Monastery", info:"The view when you get outside of the ΑΓΙΑΣΜΑ" },
    { id: 80, img: pic42S, height: 450, category: "Monastery", info: "Monastery view from the Narthex of Engleistra"  },

  
    { id: 86, img: pic49S, height: 450, category: "Monastery", info: "Engleistra view from the main square" },
    { id: 87, img: pic50S, height: 450, category: "Monastery", info: "Engleistra view from the main square" },

    { id: 94, img: pic58S, height: 450, category: "Monastery", info: "Narthex of the Engleistra of Saint Neophytos" },

 
    { id: 101, img: pic64S, height: 450, category: "Monastery" , info: "Location: above the cell of Saint Neophytos is the (Hagiastron)" },
    { id: 97, img: pic61S, height: 450, category: "Monastery", info: "Hagiastron"  },
    { id: 99, img: pic63S, height: 450, category: "Monastery", info: "Location: above the Narthex of Saint Neophytos is the (New Zion)" },
    { id: 98, img: pic62S, height: 450, category: "Monastery", info: "New Zion" },
    

    
   
    { id: 103, img: pic66S, height: 450, category: "Monastery" , info: "Engleistra view form the main square of the Monastery" },


    { id: 106, img: pic69S, height: 450, category: "Monastery", info: "The square near the Main church of the Saint Neophytos Monastery" },

   
    { id: 108, img: pic71S, height: 450, category: "Monastery", info: "The square near the Main church of the Saint Neophytos Monastery" },


    { id: 112, img: pic75S, height: 450, category: "Monastery" , info: "The Museum of Saint Neophytos" },
    { id: 119, img: pic82S, height: 450, category: "Monastery", info:"The Main church of the Monastery side view" },
    { id: 124, img: pic87S, height: 450, category: "Monastery" , info:"Entrance of the Main church of the Monastery" },

 
    { id: 130, img: pic93S, height: 450, category: "Monastery" , info: "Iconography of Saint Neophytos"  },
  ];


  const [visibilityCSS,setVisibilityCSS] = useState("hidden")

  const duplicatedItemData = [...itemData, ...itemData];
  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredImages =
    selectedCategory === "All"
      ? itemData
      : itemData.filter((item) => item.category === selectedCategory);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const [classN, setNClass] = useState("slide-images");

  const handleClass = (type) => {
    setNClass(type);
  };

  const [showModal, setShowModal] = useState(false);

  const handleModalShow = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const [zoom, setZoom] = useState(1);
  const [zoomInCount, setZoomInCount] = useState(0);

  const handleZoomIn = () => {
    if (zoomInCount < 3) {
      setZoom((prevZoom) => prevZoom * 1.1);
      setZoomInCount((prevCount) => prevCount + 1);
    }
  };

  const handleZoomOut = () => {
    console.log("hii");
    setZoom((prevZoom) => prevZoom * 0.9);
    if (zoomInCount > 0) {
      setZoomInCount((prevCount) => prevCount - 1);
    }
  };

  const handleResetZoom = () => {
    setZoom(1);
    setZoomInCount(0);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : filteredImages.length - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredImages.length);
  };

  const [showModal2, setshowModal2] = useState(false);

  const handleModalShow2 = (index) => {
    setshowModal2(true);
    setCurrentIndex(index);
  };

  const handleModalClose2 = () => {
    setshowModal2(false);
  };


  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    window.scrollTo(0, 0);
    const randomTime = Math.floor(Math.random() * (1000 - 500 + 1)) + 500; 
    const timer = setTimeout(() => {
      setLoading(false);
      setVisibilityCSS("visible")
    }, randomTime);

    return () => clearTimeout(timer); 
  }, []);


  useEffect(() => {
  
    document.body.style.overflow = loading ? 'hidden' : 'unset';
  }, [loading]);



  return (
    <div>

{loading && ( 
       <Loading/>
      )}
 <div style={{ visibility: visibilityCSS }}>

      <Grid container>
        <Grid
          item
          xs="12"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            {isGreek ? "Πινακοθήκη Αγίου Νεοφύτου" : "Saint Neofytos Gallery"}
          </h2>
        </Grid>
      </Grid>

      <Grid
        item
        xs="12"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <h3 style={{ textAlign: "center" }}>
          {" "}
          {isGreek ? "Όλες οι εικόνες" : "All images"}
        </h3>
      </Grid>

      <div
        className="slider-container"
        onClick={() => {
          handleCategoryClick("All");
          handleModalShow();
        }}
      >
        <div className="slide-images">
          {duplicatedItemData.map((item) => (
            <div key={item.id} className="slide-single-image">
              <img src={item.img} className="images" alt="" />

              {/* <LazyLoadImage
                alt={item.category}
                src={item.img}
                className="images"
                effect="blur"
                width={250}
                height={200}
              /> */}
            </div>
          ))}
        </div>
      </div>

      <Grid
        item
        xs="12"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <h3 style={{ textAlign: "center" }}>
          {isGreek
            ? "Εικόνες σχετικά με τις εικονογραφίες"
            : "Images about the Iconographies"}
        </h3>
      </Grid>

      <div
        className="slider-container"
        onClick={() => {
          handleCategoryClick("Iconography");
          handleModalShow();
        }}
      >
        <div className="slide-images2">
          {duplicatedItemData
            .filter((item) => item.category === "Iconography")
            .map((item) => (
              <div key={item.id} className="slide-single-image">
                <img src={item.img} className="images" alt="" />
                {/* <LazyLoadImage
                  alt={item.category}
                  src={item.img}
                  className="images"
                  effect="blur"
                  width={250}
                  height={200}
               
                /> */}
              </div>
            ))}
        </div>
      </div>

      <Grid
        item
        xs="12"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <h3 style={{ textAlign: "center" }}>
          {isGreek ? "Εικόνες για το Μοναστήρι" : "Images about the Monastery"}
        </h3>
      </Grid>

      <div
        className="slider-container"
        onClick={() => {
          handleCategoryClick("Monastery");
          handleModalShow();
        }}
      >
        <div className="slide-images3">
          {duplicatedItemData
            .filter((item) => item.category === "Monastery")
            .map((item) => (
              <div key={item.id} className="slide-single-image">
                <img src={item.img} className="images" alt="" />
                {/* <LazyLoadImage
                  alt={item.category}
                  src={item.img}
                  className="images"
                  effect="blur"
                  width={250}
                  height={200}
                /> */}
              </div>
            ))}
        </div>
      </div>

      <Modal
        open={showModal}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff", // White background
            padding: 16,
            borderRadius: 8,
            outline: "none", // Remove default modal outline
            overflowY: "auto", // Make content scrollable
            maxHeight: "99vh", // Set maximum height if needed
          }}
        >
          {/* Close button at the top */}
          <div style={{ textAlign: "right" }}>
            <IconButton onClick={handleModalClose} color="primary">
              <CloseIcon />
            </IconButton>
          </div>

          <Grid container spacing={2}>
            {filteredImages.map((item, index) => (
              <Grid
                item
                key={item.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={item.img}
                  className="images sizesss"
                  alt={`img${item.id}`}
                  // onClick={() =>{ handleImageClick(index)}}
                  onClick={() => handleModalShow2(index)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Modal>

      <Modal
        open={showModal2}
        onClose={handleModalClose2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 0,
          m: 0,
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleModalClose2}
            sx={{
              position: "absolute",
              top: 18,
              right: 8,
              color: "white",
              zIndex: 1300,
            }}
          >
            <CloseIcon onClick={()=>setCurrentIndex(0)} style={{ fontSize: "30px" }} />
          </IconButton>

          <div
            style={{ position: "absolute", top: 15, right: 50, zIndex: 1300 }}
          >
            <button
              onClick={handleZoomIn}
              disabled={zoomInCount >= 3}
              style={{
                margin: "0 5px",
                background: "white",
                border: "none",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              <ZoomInIcon />
            </button>
            <button
              onClick={handleZoomOut}
              disabled={zoomInCount === 0}
              style={{
                margin: "0 5px",
                background: "white",
                border: "none",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              <ZoomOutIcon />
            </button>
            <button
              onClick={handleResetZoom}
              disabled={zoomInCount === 0}
              style={{
                margin: "0 5px",
                background: "white",
                border: "none",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              <RestartAltIcon />
            </button>
          </div>

          <div
            style={{
              position: "absolute",
              top: 18,
              left: 16,
              color: "white",
              zIndex: 1300,
              fontSize: "16px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              padding: "8px",
              borderRadius: "10px",
            }}
          >
            {`${currentIndex + 1} / ${filteredImages.length}`}
          </div>

          <IconButton
            onClick={goToPrevious}
            sx={{
              position: "absolute",
              left: 5,
              top: "50%",
              transform: "translateY(-50%)",
              color: "white",
              zIndex: 1300,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              padding: "8px",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              },
            }}
          >
            <ArrowBackIosIcon
              sx={{
                transform: "translateX(4px)", // Adjust horizontal alignment if icon seems off-center
              }}
            />
          </IconButton>
          <img
            src={filteredImages[currentIndex].img}
            alt={filteredImages[currentIndex].alt}
            style={{
              maxWidth: "90%",
              maxHeight: "80vh",
              objectFit: "contain",
              transform: `scale(${zoom})`,
              transition: "transform 0.3s ease",
            }}
          />
<div style={{
  position: "absolute",
  bottom: 10,  
  left: "50%",  
  transform: "translateX(-50%)",  
  color: "white",
  zIndex: 1300,
  fontSize: "16px",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  padding: "8px",
  borderRadius: "10px",
}}>
{filteredImages[currentIndex].info}
</div>




          
          <IconButton
            onClick={goToNext}
            sx={{
              position: "absolute",
              right: 5,
              top: "50%",
              transform: "translateY(-50%)",
              color: "white",
              zIndex: 1300,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              padding: "8px",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              },
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </Modal>
      </div>
    </div>
  );
};

export default PhotoGallery;
