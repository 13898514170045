import React from "react";
import { Modal, Grid, Box } from "@mui/material";

import interview from "../assets/Videos/Monk_Interview.mp4";
import interview2 from "../assets/Videos/Monk_Interview_2.mp4";
import "../support_folder/Videos.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { useState,useEffect } from "react";
import Loading from "./Loading";
import { useLanguage } from "../hooks/ContextLanguage";

import thumbNail1 from "../assets/img/videothumbnail_1.jpg"
import thumbNail2 from "../assets/img/videothumbnail_2.jpg"


const VideoGallery = () => {
  const { isGreek } = useLanguage();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(true); 
  const [visibilityCSS,setVisibilityCSS] = useState("hidden")
  useEffect(() => {
    window.scrollTo(0, 0);
    const randomTime = Math.floor(Math.random() * (1000 - 500 + 1)) + 1000; 
    const timer = setTimeout(() => {
      setLoading(false);
      setVisibilityCSS("visible")
    }, randomTime);

    return () => clearTimeout(timer); 
  }, []);

 
  useEffect(() => {
  
    document.body.style.overflow = loading ? 'hidden' : 'unset';
  }, [loading]);

  return (
    <>
    
    {loading && ( 
       <Loading/>
      )}
 <div style={{ visibility: visibilityCSS }}>



 

    <Grid container className="center-right-logos" spacing={2}>
      <Grid item xs={12}>
        <div
          style={{ textAlign: "center", marginTop: "30px", padding: "15px" }}
        >
          <h2>{!isGreek ? `interviews about Saint Neophytos cultural heritage` : `συνεντεύξεις για την πολιτιστική κληρονομιά του Αγίου Νεοφύτου`}</h2>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <h3 className="center-text-titles">
              {!isGreek
                ? `Interview of the monk part 1`
                : `Συνέντευξη του μοναχού μέρος 1`}
            </h3>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "20px",
            }}
            className="marginTop-location_video"
          >
            <video
              controls
              className="video-adj-width"
              style={{
                maxWidth: "800px",
                aspectRatio: "1.618",
                height: "auto",
                border: "none",
              }}
              poster={thumbNail1}
            >
              <source src={interview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <h3 className="center-text-titles">
              {!isGreek ? `Interview of the monk part 2` : `Συνέντευξη του μοναχού μέρος 2`}
            </h3>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "20px",
            }}
            className="marginTop-location_video"
          >
            <video
              controls
              className="video-adj-width"
              style={{
                maxWidth: "800px",
                aspectRatio: "1.618",
                height: "auto",
                border: "none",
               
              }}
              poster={thumbNail2}
            >
              <source src={interview2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Grid>
      </Grid>

     
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width:"100%"
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpen}
            className="button-adj-width"
            sx={{
              position: "relative",
              top: "-20px",
              marginLeft: "12px",
              marginTop: "30px",
              padding: "10px 20px",
              color: "white",
              fontSize: "16px",
              textTransform: "none",
              textAlign: "center",
              borderRadius: "8px", 
              "&:hover": {
                backgroundColor: "#1565c0", 
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)", 
              },
            }}
          >
            {!isGreek
              ? "Click here to see the questions we prepared for the monk"
              : "Κάντε κλικ εδώ για να δείτε τις ερωτήσεις που ετοιμάσαμε για τον μοναχό"}
          </Button>
        </div>
  

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "80%", lg: "80%" },
            maxHeight: "90vh", 
            overflowY: "auto", 
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            borderRadius: "10px",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <CloseIcon style={{ fontSize: "30px" }} />
          </IconButton>
          <h2>
            {!isGreek ? "Questions for the Monk" : "Ερωτήσεις για τον Μοναχό"}
          </h2>
          <p>
            {!isGreek
              ? "Here are the questions we asked the monk"
              : "Εδώ είναι οι ερωτήσεις που κάναμε στον μοναχό"}
          </p>
          <p> <b> {!isGreek
                ? "Part 1"
                : "Μέρος 1"}</b> </p>
          <ol style={{ paddingLeft: "16px" }}>
            <li>
              {!isGreek
                ? "When the monastery was founded"
                : "Πότε ιδρύθηκε η μονή"}
            </li>
            <li>
              {!isGreek
                ? "Why Saint Neophytos chose the specific location "
                : "Γιατί ο Άγιος Νεόφυτος επέλεξε τη συγκεκριμένη τοποθεσία "}
            </li>
            <li>
              {!isGreek
                ? `Why Saint Neophytus called himself, \nenclosed and honorable crucifer`
                : `Γιατί ο Άγιος Νεόφυτος αποκαλούσε τον εαυτό του έγκλειστο και τίμιο σταυρίτη`}
            </li>
            <li>
              {!isGreek
                ? "What is known about the extension of the Engleistra"
                : "Τι είναι γνωστό για την επέκταση της Εγκλείστρας"}
            </li>
          </ol>

          <p> <b> {!isGreek
                ? "Part 2"
                : "Μέρος 2"}</b> </p>
          <ol style={{ paddingLeft: "16px" }}>
            <li>
              {!isGreek
                ? "What was the daily life of Saint Neophytos"
                : "Ποια ήταν η καθημερινή ζωή του Αγίου Νεόφυτου"}
            </li>
            <li>
              {!isGreek
                ? "After his death, do you know if the faithful continued to visit the monastery?"
                : "Μετά τον θάνατό του, γνωρίζετε αν οι πιστοί συνέχισαν να επισκέπτονται το μοναστήρι;"}
            </li>
            <li>
              {!isGreek
                ? `Which situation prevailed during the period of the Turkish occupation`
                : `Ποια κατάσταση επικρατούσε κατά την περίοδο της τουρκικής κατοχής`}
            </li>
            <li>
              {!isGreek
                ? `During the British occupation you know whether things were better.`
                : `Κατά τη διάρκεια της βρετανικής κατοχής ξέρετε αν τα πράγματα ήταν καλύτερα.`}
            </li>
          </ol>
        </Box>
      </Modal>
    </Grid>
    </div>
    </>
  );
};

export default VideoGallery;
