import React from "react";
import { Grid } from "@mui/material";
import Saint_Neofytos_Monastery from "../assets/img/Saint_Neophytos/pic52.jpg"
import { useLanguage } from "../hooks/ContextLanguage";
import {Button} from "@mui/material";
import { Link } from "react-router-dom";
import "../support_folder/Saint_Neophytos_Engleitra_And_Monastery.css";
import { useEffect } from "react";


const SaintNeofytoMonastery = () => {
  const { isGreek } = useLanguage();
 
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      container
      className="col1"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Grid item style={{ display: "flex",
 justifyContent: "center",
 marginTop: "50px",
 boxShadow:  "rgba(0, 0, 0, 0.35) 0px -50px 180px -28px inset",
 borderRadius:"50px" }} sm={7} md={7} lg={7}>
         <img
         className="change_photo_size"
          src={Saint_Neofytos_Monastery}
          alt="Saint Neofytos Monastery"
          style={{
            
            maxHeight: "750px",
            borderRadius: "20px",marginTop:"25px"
            ,marginBottom:"25px",
          }}
        />
      </Grid>
      <Grid
        className="col2"
        style={{ marginTop: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <h3 className="textInCenter">
          {isGreek
            ? `Σχετικά με τη Μονή Αγίου Νεοφύτου`
            : `About Saint Neofytos Monastery`}
        </h3>
      </Grid>
      <Grid
        className="col2"
        style={{
          marginTop: "15px",
          textAlign: "justify",
          marginBottom: "70px",
        }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <p className="padding-for-smaller-screens">
          {isGreek
            ? `Το μοναστήρι του Αγίου Νεοφύτου είναι ένα σεβαστό ιερό ορόσημο με προέλευση που χρονολογείται από τον 12ο αιώνα.
             Επίσης Ιδρύθηκε απο τον Άγιο Νεόφυτο το 1159 μ.Χ. 
             γι' αυτό και το μοναστήρι φέρει το όνομά του. Βρίσκεται στην πόλη της Πάφου, στην Κύπρο, και συγκεκριμένα στο χωριό Τάλα. Το μοναστήρι είναι γνωστό για
              την ιστορική του σημασία, τα συναρπαστικά έργα τέχνης και το γαλήνιο περιβάλλον του, τα οποία προσελκύουν τόσο τους προσκυνητές όσο και τους τουρίστες.`
            
   : `The Monastery of Saint Neophytos is a revered holy landmark with origins dating back to the 12th century. It was founded by Saint Neophytos the
             Recluse in 1159 AD, which is why the monastery carries his name. It is located in the city of Paphos, Cyprus, specifically in the village of Tala.
              The monastery is well-known for its historical significance, fascinating artwork, and serene surroundings, which entice both pilgrims and tourists alike.`}
        </p>
      </Grid>

      <Grid
        className="col2"
        style={{ textAlign: "justify", marginBottom: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <p style={{ margin: "0px" }} className="padding-for-smaller-screens">
          {isGreek
            ? `Η Μονή Αγίου Νεοφύτου φιλοξενεί επίσης ένα μουσείο που διαθέτει μια συλλογή από εικόνες, θρησκευτικά αντικείμενα και χειρόγραφα. Αυτό το μουσείο είναι πολύ σημαντικό γιατί παρέχει γνώσεις
             σχετικά με το παρεκκλήσι και το σπήλαιο. Μέσω του μουσείου, οι επισκέπτες μπορούν να μάθουν για το παρελθόν του μοναστηριού.
              Σκοπός του είναι να χρησιμεύσει ως πηγή γνώσης.`

            : `The Saint Neophytos Monastery also hosts a museum that features a collection of icons, religious artifacts,
             and manuscripts. This museum is very important because it provides knowledge about the
             chapel and the cave. Through the museum, visitors can learn about the past of the monastery. The purpose is to serve as a source of knowledge.`}
        </p>
      </Grid>


      <Grid
        className="col2"
        style={{ textAlign: "justify", marginBottom: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
        <p style={{ margin: "0px" }} className="padding-for-smaller-screens">
          {isGreek
            ? ` 
            Επιπλέον, το μοναστήρι είναι χτισμένο σε λόφο. Καθώς εισέρχεστε και περπατάτε μέσα στο μοναστήρι, θα δείτε μια κεντρική πλατεία. Από εκεί, μια γέφυρα που
             χτίστηκε το 1877 θα σας βοηθήσει να φτάσετε στο μοναστήρι και στο λόφο όπου δημιουργήθηκε η Εγκλείστρα του Αγίου Νεοφύτου.
              Επιπλέον, αν περπατήσετε προς την αντίθετη κατεύθυνση από τη γέφυρα, θα βρείτε την κύρια εκκλησία του μοναστηριού.`
            : 
            `Furthermore, the monastery is built on a hill. As you enter and walk into the monastery, you will see 
             a main square. From there, a bridge built in 1877 will help you reach the monastery and the hill where the Engleistra of
             Saint Neophytos was created. Additionally, if you walk in the opposite direction of the bridge, you will find the main church of the monastery.`}
        </p>
      </Grid>


      <Grid
        className="col2"
        style={{ display:"flex",justifyContent:"center", marginBottom: "70px" }}
        sm={7}
        md={7}
        lg={7}
        item
      >
       <Button
          variant="contained"
          component={Link}
          to="/"
          sx={{
            marginTop: "30px",
            width: "250px",
            color: "white", 
            textDecoration: "none",
            textTransform: 'none',
           
            "&:hover": {
              color: "white",
              textDecoration: "none",
            },
          }}
        >
          {isGreek ? "ΠΙΣΩ ΣΤΗΝ ΑΡΧΙΚΗ ΣΕΛΙΔΑ" : "BACK TO HOME PAGE"}
        </Button>

      </Grid>


    </Grid>
  );
};

export default SaintNeofytoMonastery;
