import React from 'react'
import { Grid } from '@mui/material'
import "../support_folder/border.css"
import { Box } from "@mui/material";
import { useLanguage } from "../hooks/ContextLanguage";
const Footer = () => {

  const { isGreek } = useLanguage();


  return (
    <Grid container >
        <Grid 
        sx={{backgroundColor:"#343a40"}}
        className='col2'
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{maxHeight:"350px", textAlign:"center"  }}
      >
        <Box   sx={{ minWidth: "100%", maxHeight: "420px" }}>
          <div style={{paddingLeft:"10px", paddingRight:"10px"}}>
            <p style={{color:"#F5F5F5"}}>{isGreek ? "© Copyright 2024 Όλα τα δικαιώματα διατηρούνται" : "© Copyright 2024 All Rights Reserved"}</p>
            <p style={{ fontSize: "14px", lineHeight: "14px",color:"#F5F5F5" }}>
            {isGreek ? "Αυτή η ιστοσελίδα σχεδιάστηκε και αναπτύχθηκε από τον Χαράλαμπο Μιχαηλίδη για τη διπλωματική εργασία που υποβλήθηκε στο Tepak για το πτυχίο © 2024." : "Designed and developed by Charalampos Michaelides for the thesis submitted to Tepak for the bachelor's degree © 2024."}
            </p>
          </div>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Footer
