import React from "react";
import { Grid, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import Iframe from "react-iframe";
import "../../support_folder/games/gamelvls.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../support_folder/marginsSmallScreen.css"
import { useLanguage } from "../../hooks/ContextLanguage";
import { useEffect, useRef,useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Loading from "../Loading";
const GameLVL2 = () => {


  



  
  const { isGreek } = useLanguage();


  const [age, setAge] = React.useState("");
  const navigate = useNavigate(); // Initialize useNavigate
  const [loading, setLoading] = useState(true);


  const [visibilityCSS,setVisibilityCSS] = useState("hidden")

  const handleChange = (event) => {
    const selectedLVL = event.target.value;
    setAge(selectedLVL);

    
    if (selectedLVL === 10) {
      navigate("/games/lvl1"); 
    } else if (selectedLVL === 20) {
      navigate("/games/lvl2");
    } else if (selectedLVL === 30) {
      navigate("/games/lvl3"); 
    }
  };


  const iframeRefCards = useRef(null);
  const iframeRefQuestions = useRef(null);
  const iframeRefCrossWord = useRef(null);
  const iframeRefPuzzle = useRef(null);


  const resizeIframe = (iframe) => {
    if (!iframe) return;
    iframe.onload = () => {
        setTimeout(() => {
            try {
                const doc = iframe.contentDocument || iframe.contentWindow.document;
                const height = doc.documentElement.scrollHeight || doc.body.scrollHeight;
                iframe.style.height = height + 'px';
                setLoading(false)
                setVisibilityCSS("visible")
            } catch (e) {
                console.error("Error adjusting iframe height: ", e);
            }
        }, 100);
    };
};

useEffect(() => {
    resizeIframe(iframeRefCards.current);
    resizeIframe(iframeRefQuestions.current);
    resizeIframe(iframeRefCrossWord.current);
    resizeIframe(iframeRefPuzzle.current);
}, []); 

useEffect(() => {
  
  document.body.style.overflow = loading ? 'hidden' : 'unset';
}, [loading]);

  return (

    <>

{loading && ( 
       <Loading/>
      )}
 <div style={{ visibility: visibilityCSS }}>

    <Grid container>
      <Grid
        item
        className="col1"
        xs="12"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 style={{marginTop:"30px"}}>{isGreek ? `Καλώς ήρθατε στο επίπεδο 2` : `Welcome to Level 2`}</h1>
      </Grid>

      <Grid
        item
        className="col1"
        xs="12"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "50%", marginTop: "20px" }} className="center-div-small-screen">
          <FormControl style={{ width: "180px" }}>
            <InputLabel id="demo-simple-select-label">{isGreek? `Επιλέξτε Επίπεδο` : `Choose Level`}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={10}>
              
              {isGreek? `Επίπεδο 1` : `Level 1`}
                
              </MenuItem>
              <MenuItem value={20} disabled>
              {isGreek? `Επίπεδο 2` : `Level 2`}
              </MenuItem>
              <MenuItem value={30}>{isGreek? `Επίπεδο 3` : `Level 3`}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grid>

      <Grid
        item
        className="col1"
        xs="12"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "50%", marginTop: "20px" }}>
          <h3 className="textAlign-small-screen">{isGreek? `Παιχνίδι μνήμης` : `Memory game`}</h3>
          <p className="textAlign-small-screen">{isGreek? `προσπαθήστε να ταιριάξετε τις φωτογραφίες, καλή τύχη` : `try to match the photos, good luck`}</p>
          
          <iframe
          ref={iframeRefCards}
          src="/games/LVL2/IMGMEMORYGAMELVL2.html"
          width="100%"
          id="h5p-iframe"
          frameBorder="0"
          allowFullScreen
          style={{ position: "relative", display: "initial" }}
          onLoad={resizeIframe}
        />
        </div>
      </Grid>
      <Grid
        item
        className="col1"
        xs="12"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "50%", marginTop: "20px" }}>
          <h3 className="textAlign-small-screen">{isGreek? `Ερωτήσεις σχετικά με το μοναστήρι` : `Questions about the monastery`}</h3>
          <p className="textAlign-small-screen">{isGreek? `κάντε κλικ στη σωστή απάντηση, μόνο μία απάντηση είναι η σωστή` : `click the correct answer, only one answer is the correct`}</p>
          
         
           {isGreek ?
  <iframe
  ref={iframeRefQuestions}
    src="/games/LVL2/SingleQuestionsGreek.html"
    width="100%"
    style={{ border: "0", display: "initial", position: "relative" }}
    id="h5p-iframe"
    className="h5p-iframe "
    allowFullScreen
    onLoad={resizeIframe}
  />
:
  <iframe
  ref={iframeRefQuestions}
    src="/games/LVL2/SingleQuestions.html"
    width="100%"
    style={{ border: "0", display: "initial", position: "relative" }}
    id="h5p-iframe"
    className="h5p-iframe "
    allowFullScreen
    onLoad={resizeIframe}
  />
}
          
        </div>
      </Grid>
      <Grid
        item
        className="col1"
        xs="12"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "50%", marginTop: "20px" }}>
          <h3 className="textAlign-small-screen">{isGreek? `Κρυμμένες λέξεις για το μοναστήρι` : `Hidden words about the monastery`}</h3>
          <p className="textAlign-small-screen">{isGreek? `Βρείτε τις κρυμμένες λέξεις, καλή τύχη` : `Find the hidden words, good luck`}</p>
         
           {isGreek ?
  <iframe  
  ref={iframeRefCrossWord}
    src="/games/LVL2/CrossWordsGreekLVL2.html"
    width="100%"
    style={{ border: "0", display: "initial", position: "relative" }}
    id="h5p-iframe"
    className="h5p-iframe"
    allowFullScreen
    onLoad={resizeIframe}
  />
: 
  <iframe
  ref={iframeRefCrossWord}
    src="/games/LVL2/CrossWords.html"
    width="100%"
    style={{ border: "0", display: "initial", position: "relative" }}
    id="h5p-iframe"
    className="h5p-iframe"
    allowFullScreen
    onLoad={resizeIframe}
  />
}
        </div>
      </Grid>

      <Grid
        item
        className="col1"
        xs="12"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "50%", marginTop: "20px" }}>
          <h3 className="textAlign-small-screen">{isGreek? `Παιχνίδι παζλ` : `Puzzle Game`}</h3>
          <p className="textAlign-small-screen">{isGreek? `Σύρετε και αφήστε τα κομμάτια του παζλ στη σωστή θέση` : `Drag and Drop the puzzle pieces to the right place`}</p>
         
          <iframe
    
    src="/games/LVL2/Puzzle LVL2.html"
    width="100%"
    style={{ border: "0", display: "initial", position: "relative" }}
    id="h5p-iframe"
    className="h5p-iframe size-game-puzzle-lvl2"
    allowFullScreen
    
  />
        </div>
      </Grid>
    </Grid>
    </div>
    </>
  );
};

export default GameLVL2;
